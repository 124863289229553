<ion-item routerDirection="forward" detail="true">
  <ion-thumbnail slot="start" aria-hidden="true">
    <app-playlist-icon [playlist]="playlist" [showNewPlaylistIcon]="showNewPlaylistIcon"></app-playlist-icon>
  </ion-thumbnail>
  <ion-label class="ion-text-wrap">
    <h2 *ngIf="playlist.id===-1" class="text-primary" role="link">{{ 'PLAYLISTS.NEWPLAYLIST' | translate }}</h2>
    <h2 *ngIf="playlist.id!==-1" role="link">{{ playlist.designation }}</h2>
    <!--<p *ngIf="tune.searchNote && showSearchHints" >{{ tune.searchNote }}</p>-->
  </ion-label>
</ion-item>
