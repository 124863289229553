import { ConnectivityService } from '../services/connectivity/connectivity.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  isAuth = false;

  constructor(private authService: AuthenticationService, private router: Router, private conService: ConnectivityService) {
    authService.isAuthenticated
    .subscribe(auth => this.isAuth = auth);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if(this.authService.isRefreshing) {
          return true;
        } else if(!this.isAuth) {
          this.router.navigate(['/collection/user/login']);
        }
        return this.authService.isAuthenticated;
  }
}
