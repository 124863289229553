<!--<ion-header>
  <ion-toolbar>
    <ion-title>Bitte warten</ion-title>
  </ion-toolbar>
</ion-header>-->

<ion-content scrollY="false">
  <ion-text style="text-align: center;">
    <p class="title"><b>{{ 'MODAL.WAIT' | translate }}</b></p>
    <p *ngIf="downloadSize>0" >
      {{ 'MODAL.FILESLOADING' | translate }}<br>
      <!--{{ downloadProgressMB.toFixed(2) }} MB von {{ downloadSize.toFixed(2) }} MB-->
    </p>
    <p *ngIf="downloadSize===0">
      {{ 'MODAL.FILESLOADING' | translate }}
    </p>
  </ion-text>
  <ion-progress-bar *ngIf="downloadSize>0" [value]="downloadProgress"></ion-progress-bar>
  <ion-spinner *ngIf="downloadSize===0" name="circular"></ion-spinner><br>
  @if(routeBack) {
    <ion-button fill="clear" class="bottom-button" (click)="cancelDownload()">{{ 'MODAL.CANCEL' | translate }}</ion-button>
  }
</ion-content>
