import { Component, OnInit, inject } from '@angular/core';
import { AudioService } from 'src/app/services/audio/audio.service';
import { InstrumentGroup, Track, Level } from 'bandon-shared';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RecordingItemComponent } from '../recording-item/recording-item.component';
import { InstrumentItemComponent } from '../instrument-item/instrument-item.component';
import { LevelIconComponent } from '../level-icon/level-icon.component';
import { IonicModule } from '@ionic/angular';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-mixer',
    templateUrl: './mixer.component.html',
    styleUrls: ['./mixer.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IonicModule,
        LevelIconComponent,
        NgFor,
        InstrumentItemComponent,
        RecordingItemComponent,
        TranslateModule,
    ],
})
export class MixerComponent implements OnInit {
  translate = inject(TranslateService)

  private isMuting = false;
  private isSoloing = false;

  constructor(private audioService: AudioService) { }

  get instrumentGroups(): InstrumentGroup[] {
    return this.audioService.getInstrumentGroups().sort((g1, g2) => g1.grouporder-g2.grouporder);
  }

  get hasLevels(): boolean {
    let out = false;
    this.instrumentGroups.forEach(g => out = out || this.hasGroupLevels(g));
    return out;
  }

  get overallLevel(): number {
    let out = -1;
    this.instrumentGroups.forEach(g => {
      const groupLevel = this.getGroupLevel(g);
      if(out===-1 && groupLevel!==-1) {
        out = groupLevel;
      } else if(out!==groupLevel && groupLevel!==-1) {
        out = 0;
      }
    });
    return out;
  }

  get ariaOverallLevel(): string {
    if(this.overallLevel == 1) {
      return 'A'
    } else if(this.overallLevel == 2) {
      return 'B'
    } else if(this.overallLevel == 3) {
      return 'C'
    }
    return this.translate.instant('MIXER.UNDEFINED')
  }

  get overallLevels(): Level[] {
    const out: Level[] = [];
    this.instrumentGroups.forEach(g => {
      this.getPossibleLevels(g).forEach(level => {
        if (!out.find(e => e.id===level.id)) {
          out.push(level);
        }
      });
    });
    return out;
  }

  get hasRecording(): boolean {
    return this.audioService.hasRecording;
  }

  ngOnInit() {
  }

  getTracksOfGroup(group: InstrumentGroup): Track[] {
    return this.audioService.getTracksOfGroup(group).sort((t1, t2) => {
      if(t1.voice && t1.voice.id>0 && t2.voice && t2.voice.id>0) {
        return t1.voice.voiceorder-t2.voice.voiceorder;
      }
      return t1.instrument.instrumentorder-t2.instrument.instrumentorder;
    });
  }

  isChangingSlider(event: any) {
    console.log(event);
  }

  hasGroupLevels(group: InstrumentGroup): boolean {
    const levels = this.audioService.getLevelsToInstrumentGroup(group);
    return group.linked && levels.length>0;
  }

  getPossibleLevels(group: InstrumentGroup): Level[] {
    return this.audioService.getLevelsToInstrumentGroup(group);
  }

  getPopoverTriggerID(group: InstrumentGroup): string {
    return 'popover-trigger-group-'+group.id;
  }

  switchGroupLevel(group: InstrumentGroup, level: Level) {
    //TODO
    const instruments = this.audioService.getInstrumentOfGroup(group);
    instruments.forEach(instr => {
      //TODO: Voice?
      this.audioService.switchInstrumentLevel(instr.instrument, instr.voice, level);
    });
    group.groupLevel = level.id;
  }

  getGroupLevel(group: InstrumentGroup): number {
    if(this.audioService.getLevelsToInstrumentGroup(group).length===0) {
      return -1;
    }
    if(group.linked) {
      return group.groupLevel;
    }
    let selectedLevel = -1;
    this.audioService.getTracksOfGroup(group).forEach(track => {
      if(track.shown && track.level) {
        if(selectedLevel===-1) {
          selectedLevel = track.level.id;
        } else if(selectedLevel!==track.level.id) {
          selectedLevel = 0;
        }
      }
    });
    return selectedLevel;
  }

  switchTuneLevel(level: Level) {
    this.instrumentGroups.forEach(g => {
      this.switchGroupLevel(g, level);
    });
  }

  getSoloColor(group: InstrumentGroup) {
    if(group.solo) {
      return 'primary';
    }
    return 'light';
  }

  async muteGroup(group: InstrumentGroup) {
    if(this.isMuting) {
      return;
    }
    this.isMuting = true;
    const tracks = this.audioService.getTracksOfGroup(group);
    if(group.muted) {
      for (const track of tracks) {
        await this.audioService.muteTrack(track, false);
      }
//      this.audioService.getTracksOfGroup(group).forEach(track => this.audioService.muteTrack(track, false));
    } else {
      for (const track of tracks) {
        await this.audioService.muteTrack(track, true);
      }
//      this.audioService.getTracksOfGroup(group).forEach(track => this.audioService.muteTrack(track, true));
    }
    this.isMuting = false;
  }

  async soloGroup(group: InstrumentGroup) {
    if(this.isSoloing) {
      return;
    }
    this.isSoloing = true;
    const tracks = this.audioService.getTracksOfGroup(group);
    if(group.solo) {
      for (const track of tracks) {
        await this.audioService.soloTrack(track, false);
      }
//      this.audioService.getTracksOfGroup(group).forEach(track => this.audioService.soloTrack(track, false));
    } else {
      for (const track of tracks) {
        await this.audioService.soloTrack(track, true);
      }
//      this.audioService.getTracksOfGroup(group).forEach(track => this.audioService.soloTrack(track, true));
    }
    this.isSoloing = false;
  }

  hasGroupMute(group: InstrumentGroup): boolean {
    if(this.audioService.getInstrumentOfGroup(group).length>1) {
      return true;
    }
    return false;
  }

  hasGroupSolo(group: InstrumentGroup): boolean {
    return this.hasGroupMute(group);
  }

  resetMix() {
    this.audioService.resetMix();
  }
}
