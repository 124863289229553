import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage, ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-img-cropper',
    templateUrl: './img-cropper.component.html',
    styleUrls: ['./img-cropper.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        ImageCropperModule,
        TranslateModule,
    ],
})
export class ImgCropperComponent  implements OnInit {
  @ViewChild('cropper') cropper: ImageCropperComponent;

  @Input() image;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  isMobile = Capacitor.getPlatform() !== 'web';

  constructor(
    private sanitizer: DomSanitizer,
    private modalController: ModalController
  ) {
  }

  ngOnInit(): void {

  }

  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  dismissModal() {
    this.modalController.dismiss(null, 'cancel');
  }

  cropImage() {
    this.cropper.crop();
  }

  imageCropped($event: any) {
    this.modalController.dismiss($event.objectUrl, 'confirm');
  }
}
