import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, ActionPerformed, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { AuthenticationService } from '../auth/authentication.service';
import { FcmTokensService } from './fcm-tokens.service';
import { ContactsService } from '../social/contacts.service';
import { GroupsService } from '../social/groups.service';
import { UserMessagesService } from '../social/user-messages.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  authService = inject(AuthenticationService)
  fcmTokenService = inject(FcmTokensService)
  contactsService = inject(ContactsService)
  groupsService = inject(GroupsService)
  userMessagesService = inject(UserMessagesService)

  fcm_token: string | undefined;

  constructor(private router: Router) { }

  init() {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }

    PushNotifications.checkPermissions().then(permStatus => {
      if (permStatus.receive === 'granted') {
        PushNotifications.register();
      } else {
        //TODO: show some error
      }
    });
//    console.log('Init Notification Service');

/*    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      } else {
        //TODO: show some error
      }
    });*/

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        if(this.authService.user) {
          this.fcmTokenService.storeToken(this.authService.user, token.value);
        }
        this.fcm_token = token.value;
        console.log('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
//        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
//        console.log('pushNotificationReceived', notification)
        //Flags
        if(notification.data.refreshContacts) {
          this.contactsService.refreshContacts();
        }
        if(notification.data.refreshGroups) {
          this.groupsService.refreshGroups();
        }
        this.userMessagesService.refreshMessages();
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        const data = notification.notification.data;
        if(data.page) {
          this.router.navigateByUrl(data.page);
        } else if(data.collectionid) {
          this.router.navigateByUrl(`/collection/store/collection/${data.collectionid}`);
        } else {
          this.router.navigateByUrl(`/collection/store`);
        }

      }
    );

    this.authService.isAuthenticated.subscribe(auth => {
      if(!auth && this.fcm_token) {
        this.fcmTokenService.deleteToken(this.fcm_token);
      }
    })

    this.authService.user$.subscribe(user => {
      if(user && this.fcm_token) {
        this.fcmTokenService.storeToken(user, this.fcm_token);
      }
    })

  }

  requestPermissions() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      } else {
        //TODO: show some error
      }
    });
  }
}
