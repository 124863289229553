import { CommonModule } from "@angular/common";
import { Directive, HostListener, NgModule } from "@angular/core";

@Directive({
  selector: '[appLowercase]',
  standalone: true,
})
export class LowercaseDirective {
  @HostListener('ionInput', ['$event']) onInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement
    input.value = input.value.toLowerCase()
  }
}
