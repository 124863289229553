import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {

  public appIsOnline$: Observable<boolean>;
  public isSlowConnection: boolean;

  constructor(private httpClient: HttpClient) {
    this.initConnectivityMonitoring();
  }

  public isConnected(): boolean {
    return navigator.onLine;
  }

  private initConnectivityMonitoring() {
    if (!window || !navigator || !('onLine' in navigator)) {
      return;
    }

    this.appIsOnline$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(map(() => navigator.onLine));


  }

  checkConnection() {
    if(this.isConnected()) {
      const url = `${environment.apiURL}/helloworld`; // Replace with your server URL
      this.checkConnectionSpeed(url).subscribe(duration => {
        // Set your threshold value based on your requirements
        const threshold = 1000; // in milliseconds
        this.isSlowConnection = duration > threshold;
//        console.log(`Speed ${duration}: ${threshold}`);
      });
    } else {
      this.isSlowConnection = false;
    }

  }

  private checkConnectionSpeed(url: string): Observable<number> {
    const startTime = new Date().getTime();

    return this.httpClient.get(url, { observe: 'response' })
      .pipe(map(response => {
      const endTime = new Date().getTime();
      const duration = endTime - startTime;
      return duration;
    }));
  }
}
