/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AlertController, LoadingController, IonicModule } from '@ionic/angular';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ShowHidePasswordComponent } from '../../general/show-hide-password/show-hide-password.component';
import { NgIf } from '@angular/common';
import { LowercaseDirective } from 'src/app/directives/lowercase.directive';

@Component({
    selector: 'app-login-screen',
    templateUrl: './login-screen.component.html',
    styleUrls: ['./login-screen.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IonicModule,
        FormsModule,
        ShowHidePasswordComponent,
        RouterLink,
        TranslateModule,
        LowercaseDirective
    ],
})
export class LoginScreenComponent  implements OnInit, OnDestroy {
  alertController = inject(AlertController)
  authService = inject(AuthenticationService)
  connService = inject(ConnectivityService)
  loadingController = inject(LoadingController)
  router = inject(Router)
  translate = inject(TranslateService)

  _email: string;
  _password: string;

  isConnected = false;

  private unsubscribe$ = new Subject<void>();


  get isWeb() {
    return Capacitor.getPlatform() === 'web';
  }

  get isAndroid() {
    return Capacitor.getPlatform() === 'android';
  }

  get email() {
    return this._email;
  }

  set email(email: string) {
    this._email = email;
  }

  get password() {
    return this._password;
  }

  set password(password: string) {
    this._password = password;
  }

  get isSubmitEnabled(): boolean {
    if(this.email && this.email.length>0 && this.password && this.password.length>6) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.connService.appIsOnline$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(c => {
        this.isConnected = c;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async login() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    if (Capacitor.getPlatform() === 'ios') {
      await SavePassword.promptDialog({
          username: this.email,
          password: this.password
      })
      .then(() => console.log('promptDialog success'))
      .catch((err) => console.error('promptDialog failure', err));
    }
    await loading.present();

    this.authService.login({email: this.email, password: this.password})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: async response => {
        loading.dismiss();

        this.router.navigateByUrl('collection', { replaceUrl: true, state: {animation: 'Login'} });
      },
      error: error => {
        loading.dismiss();
        this.showLoginError(error);
      }
    });

  }

  async loginWithApple() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.authService.loginWithApple().then(observable => {
      observable
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: response => {
          loading.dismiss();
          this.router.navigateByUrl('collection', { replaceUrl: true });
        },
        error: error => {
          loading.dismiss();
          this.showLoginError(error);
        }
      });
    }).catch(error => {
      loading.dismiss();
//      this.showAlert('Login failed', 'Please contact the band-on-Team: '+error);
    });
  }

  async loginWithGoogle() {
    const loading = await this.loadingController.create({
      cssClass: 'band-on-loading'
    });
    await loading.present();

    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.authService.loginWithGoogle().then(observable => {
      observable
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: response => {
          loading.dismiss();
          this.router.navigateByUrl('collection', { replaceUrl: true });
        },
        error: error => {
          loading.dismiss();
          this.showLoginError(error);
        }
      });
    })
    .catch(error => {
      this.showAlert(this.translate.instant('LOGIN.ERRORUNKNOWNTITLE'), this.translate.instant('LOGIN.ERRORUNKNOWNMSG'));
      loading.dismiss();
    });
  }

  showLoginError(error) {
    if(error.status===403) {
      if(this.isWeb) {
        this.showAlert(this.translate.instant('LOGIN.ERRORDEVICEWEBTITLE'), this.translate.instant('LOGIN.ERRORDEVICEWEBMSG'));
      } else {
        this.showAlert(this.translate.instant('LOGIN.ERRORDEVICETITLE'), this.translate.instant('LOGIN.ERRORDEVICEMSG'));
      }
    } else if(error.status===401) {
      this.showAlert(this.translate.instant('LOGIN.ERRORFAILEDTITLE'), this.translate.instant('LOGIN.ERRORFAILEDMSG'));
    } else {
      this.showAlert(this.translate.instant('LOGIN.ERRORUNKNOWNTITLE'), this.translate.instant('LOGIN.ERRORUNKNOWNMSG'));
    }
  }

  async showAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      cssClass: 'band-on-alert',
      buttons: ['OK']
    });
    await alert.present();
  }
}
