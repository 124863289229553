<ion-card [class]="cssClass" [@cardAnimation]="showRange" [id]="cardID">
  <ion-card-header>
    <ion-card-title [color]="titleColor" role="gridcell">
      <ion-grid class="ion-no-padding ion-no-margin">
        <ion-row class="ion-align-items-center">
          <ion-col size="1" class="ion-align-items-center ion-no-padding" (click)="switchStartLoopState()">
            <ion-icon src="/assets/icon/Wiederholunga.svg"
              size="large"
              [color]="getStartLoopColor"
              role="button"
              [attr.aria-label]="ariaLoopStart"
            >
            </ion-icon>
          </ion-col>
          <ion-col size="10" (click)="jump()" >
            <ion-text class="height: 100%" role="button">
              <div [attr.aria-label]='ariaDesignation' >
                {{ part.designation }}
              </div>
            </ion-text>
          </ion-col>
          <ion-col size="1" (click)="switchEndLoopState()">
            <ion-icon src="/assets/icon/Wiederholungb.svg"
              size="large"
              [color]="getEndLoopColor"
              role="button"
              [attr.aria-label]="ariaLoopEnd"
            >
            </ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-title>
  </ion-card-header>
  <ion-card-content [class]="cardContentClass" >
    @if (showRange && isDualKnob) {
      <ion-range
        [attr.aria-label] = "ariaLoopSlider"
        role = "slider"
        [dualKnobs]="true"
        [pin]="true"
        [min]="rangeMinValue"
        [max]="rangeMaxValue"
        [ticks]="hasBarInformation"
        [snaps]="hasBarInformation"
        [pinFormatter]="pinFormatter"
        [(ngModel)]="rangeValue"
        (ionKnobMoveEnd)="loopChange($event)"
        (ionKnobMoveStart)="setIsChanging(true)"
      >
      </ion-range>
    }
    @if(showRange && !isDualKnob) {
      <ion-range
        [attr.aria-label] = "ariaLoopSlider"
        role = "slider"
        [pin]="true"
        [min]="rangeMinValue"
        [max]="rangeMaxValue"
        [ticks]="hasBarInformation"
        [snaps]="hasBarInformation"
        [pinFormatter]="pinFormatter"
        [(ngModel)]="rangeValue"
        (ionKnobMoveEnd)="loopChange($event)"
        (ionKnobMoveStart)="setIsChanging(true)">
    </ion-range>    }
  </ion-card-content>
</ion-card>
