<ion-list lines="none" detail="false" no-padding #arrangementView>
    <!--    <ion-item tappable (click)="toggleArrangementTab()" [ngClass]="{'section-active': showArrangement, 'section': !showArrangement}">
        <ion-title>Arrangement</ion-title>
      </ion-item>-->

  <app-arrangement-part *ngFor="let part of parts"
      [tune]="tune"
      [part]="part"
      [isActive]="part.selected"
      [isStartLoop]="isStartLoopPart(part)"
      [isEndLoop]="isEndLoopPart(part)"
      (setLoopStartPart)="setLoopStartPart($event, part)"
      (setLoopEndPart)="setLoopEndPart($event, part)"
      (setLoopTimes)="setLoopTime($event)"
      (jumpToPart)="jumpToPart(part)"
      [id]="getPartID(part)"></app-arrangement-part>
</ion-list>
