import { Injectable } from '@angular/core';
import { AudioEngine } from '@musicdose/audioengine';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';

interface ActiveDownload {
  trackid: number;
  filesize: number;
  downloadProgress: number;
  finished: boolean;
  blocking: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AudioDownloadService {

  public progressBehaviour = new BehaviorSubject<number>(0);
  public progress$ = this.progressBehaviour.asObservable();

  public backgroundProgressBehaviour = new BehaviorSubject<number>(0);
  public backgroundProgress$ = this.backgroundProgressBehaviour.asObservable();

  public completedBehaviour = new BehaviorSubject<boolean>(false);
  public completed$ = this.completedBehaviour.asObservable();

  public trackCompleteBehaviour = new BehaviorSubject<number>(0);
  public trackCompleted$ = this.trackCompleteBehaviour.asObservable();

  public countOffCompleteBehaviour = new BehaviorSubject<boolean>(false);
  public countOffCompleted$ = this.countOffCompleteBehaviour.asObservable();

  //Blocking downloads
  public downloadProgress = 0;
  public downloadSize = 0;
  //Background downloads
  public backgroundDownloadProgress = 0;
  public backgroundDownloadSize = 0;

  private activeDownloads: ActiveDownload[] = [];

  private lastPublishedProgress = 0;
  private lastPublishedBackgProgress = 0;

  private isAuth = false;

  constructor(
    private authService: AuthenticationService
  ) { }

  async init() {
    await AudioEngine.addListener('audioLoaded', res => {
      const download = this.activeDownloads.find(d => d.trackid === res.trackid);
      if(download) {
        download.finished = true;
        download.downloadProgress = download.filesize;
      }
      this.trackCompleteBehaviour.next(res.trackid);

      let allFinished = true;
      this.activeDownloads.forEach(d => {
//        console.log('Download finished: ', d.trackid, d.blocking, allFinished);
        if(d.blocking) {
          allFinished &&= d.finished;
        } else {
          //TODO: what?
        }
      });

      if(allFinished) {
        this.finishLoading();
      }
    });
    await AudioEngine.addListener('countOffLoaded', () => {
      this.countOffCompleteBehaviour.next(true);

      let allFinished = true;
      this.activeDownloads.forEach(d => {
        if(d.blocking) {
          allFinished &&= d.finished;
        } else {
          //TODO: what?
        }
      });

      if(allFinished) {
        this.finishLoading();
      }
    });
    await AudioEngine.addListener('audioLoadProgress', res => {
      const download = this.activeDownloads.find(d => d.trackid === res.trackid);
      if (download) {
        download.downloadProgress = res.progress;
      }

      this.downloadProgress = 0;
      this.downloadSize = 0;
      this.backgroundDownloadProgress = 0;
      this.backgroundDownloadSize = 0;
      this.activeDownloads.forEach(d => {
        if(d.blocking) {
          this.downloadSize += d.filesize;
          this.downloadProgress += d.downloadProgress;
        }
        this.backgroundDownloadSize += d.filesize;
        this.backgroundDownloadProgress += d.downloadProgress;
      });
      this.downloadProgress = this.downloadProgress/this.downloadSize * 100;
      this.backgroundDownloadProgress = this.backgroundDownloadProgress/this.backgroundDownloadSize * 100;

      if(Math.abs(this.downloadProgress-this.lastPublishedProgress) > 1) {
        this.progressBehaviour.next(this.downloadProgress);
        this.lastPublishedProgress = this.downloadProgress;
      }
      if(Math.abs(this.backgroundDownloadProgress-this.lastPublishedBackgProgress) > 1) {
        this.backgroundProgressBehaviour.next(this.backgroundDownloadProgress);
        this.lastPublishedBackgProgress = this.backgroundDownloadProgress;
      }
    });

    this.authService.isAuthenticated
    .subscribe(auth => this.isAuth = auth);
  }

  finishLoading() {
    this.completedBehaviour.next(true);
  }

  downloadFile(trackID: number, trackPath: string, checksum: string, filesize: number, blocking = true) {
    const download = this.activeDownloads.find(d => d.trackid===trackID);
    if(download) {
      return;
    }

    this.completedBehaviour.next(false);

    let token = environment.apiKey;
    if (this.isAuth) {
      token = this.authService.getToken();
    }

    const options = {
      accessToken: token,
      path : trackPath,
      trackID,
      fileMD5: checksum,
    };
    this.activeDownloads.push({
      trackid: trackID,
      filesize,
      downloadProgress: 0,
      finished: false,
      blocking
    });
    AudioEngine.downloadTrack(options);
  }

  downloadCountOff(speedID: number, file: string, checksum: string, filesize: number, blocking = true) {
    const download = this.activeDownloads.find(d => d.trackid===-speedID);
    if(download) {
      return;
    }

    let token = environment.apiKey;
    if (this.isAuth) {
      token = this.authService.getToken();
    }

    this.completedBehaviour.next(false);
    const options = {
      accessToken: token,
      tuneSpeedId : speedID,
      filePath: file,
      fileMD5: checksum
    };

    this.activeDownloads.push({
      trackid: -speedID,
      filesize,
      downloadProgress: 0,
      finished: false,
      blocking
    });
    AudioEngine.downloadCountOff(options);

  }

  clearDownloads() {
    let allFinished = true;
    for(let d of this.activeDownloads) {
      allFinished &&= d.finished;
    }

    if(allFinished) {
      this.activeDownloads.length = 0;
      this.downloadSize = 0;
      this.downloadProgress = 0;
      this.lastPublishedProgress = 0;
    }
  }

  cancelDownloads() {
    for(let d of this.activeDownloads) {
      AudioEngine.cancelDownload({id: String(d.trackid)});
    }
    this.activeDownloads.length = 0;
    this.clearDownloads();
  }

  setIsBlocking(trackID: number, blocking: boolean): boolean {
    const download = this.activeDownloads.find(d => d.trackid===trackID);
    if(download && !download.finished) {
      download.blocking = blocking;
      return true;
    }
    return false;
  }

  getDownloadCount(): number {
    return this.activeDownloads.filter(d => !d.finished).length;
  }
}
