import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { Collection, SimpleTuneModel, Tune } from 'bandon-shared';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BandONTranslationsService {

  constructor(
    private authService: AuthenticationService,
    private translate: TranslateService
  ) { }

  getTuneTitle(tune: SimpleTuneModel | Tune) {
    if(this.authService.user && tune) {
      const translation = tune.translations.find(e => e.languageid===this.authService.user.language);
      if(translation && translation.title) {
        return translation.title;
      }
      return tune.title;
    } else if(tune) {
      return tune.title;
    }
    return '';
  }

  getTuneSubtitle(tune: SimpleTuneModel | Tune) {
    if(this.authService.user && tune && tune.subtitle) {
      const translation = tune.translations.find(e => e.languageid===this.authService.user.language);
      if(translation && translation.subtitle) {
        return translation.subtitle;
      }
      return tune.subtitle;
    } else if(tune && tune.subtitle) {
      return tune.subtitle;
    }
    return '';
  }

  getCollectionTitle(collection: Collection) {
    if(this.authService.user && collection) {
      const translation = collection.translations.find(e => e.languageid===this.authService.user.language);
      if(translation && translation.designation) {
        return translation.designation;
      }
      return collection.designation;
    } else if(collection) {
      return collection.designation;
    }
    return '';
  }

  getCollectionDesc(collection: Collection) {
    if(this.authService.user && collection) {
      const translation = collection.translations.find(e => e.languageid===this.authService.user.language);
      if(translation && translation.description) {
        return translation.description;
      }
      return collection.description;
    } else if(collection) {
      return collection.description;
    }
    return '';
  }

  getTonalityString(tonalities: string) {
    let tonalityString = this.translate.instant('PLAYER.TONALITY');
    if(tonalities.split(',').length>1) {
      tonalityString = this.translate.instant('PLAYER.TONALITIES')
    }
    for(let tonality of tonalities.split(',')) {
      //TODO: verbessern, evtl. in Service auslagern
      if(this.translate.currentLang==='de') {
        tonality = tonality.replace('B', 'H')
        tonality = tonality.replace('b', 'es')
        tonality = tonality.replace('#', 'is')
        tonality = tonality.replace('Aes', 'As')
        tonality = tonality.replace('Ees', 'Es')
        tonality = tonality.replace('Hes', 'B')
        tonality = tonality.replace('-', '-'+this.translate.instant('PLAYER.MINOR'))
      } else if(this.translate.currentLang==='en') {
        tonality = tonality.replace('b', ' flat')
        tonality = tonality.replace('#', ' sharp')
        tonality = tonality.replace('-', '-'+this.translate.instant('PLAYER.MINOR'))
      }
      tonalityString += ' '+tonality
    }
    return tonalityString;
  }

  getTempiString(speeds: string) {
    let tempoString = this.translate.instant('PLAYER.SPEED');
    if(speeds.split(',').length>1) {
      tempoString = this.translate.instant('PLAYER.SPEEDS');
    }
    return tempoString + speeds;
  }

  getTuneDescription(tune: SimpleTuneModel) {
    let tonalityString = this.getTonalityString(tune.tonalities);
    let tempoString = this.getTempiString(tune.speeds);
    const title = this.getTuneTitle(tune);
    const subtitle = this.getTuneSubtitle(tune);
    let out = `${this.translate.instant('PLAYER.TUNE')} ${title}, ${subtitle}, ${tonalityString}, ${tempoString}`;
    return out;
  }

  getCollectionDescription(collection: Collection) {
    return `${this.translate.instant('COLLECTION.COLLECTION')} ${this.getCollectionTitle(collection)}: ${this.getCollectionDesc(collection)}`;
  }
}
