import { HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';


const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthenticationService | undefined;

  private refreshObservable: Observable<string> | undefined;

  constructor(private inj: Injector, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req;

    return next.handle(authReq).pipe(catchError((error: any) => {
      if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/login') && error.status === 401) {
        return this.handle401Error(authReq, next);
      }

      return throwError(() => error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.inj.get(AuthenticationService);
    if (!this.authService.isRefreshing) {
//      this.isRefreshing = true;

      const token = this.authService.getRefreshToken();

      if (token) {
        this.refreshObservable = this.authService.refreshToken();
        return this.refreshObservable.pipe(
          switchMap((response: any) => {
            console.log('token is refreshed, try request again');

            return next.handle(this.addTokenHeader(request, response.access_token));
          }),
          catchError((err) => {
            if(err.status === 401 || err.status === 403) {
              this.logout();
              return throwError(() => new Error(err));
            }
          })
        );
      } else {
        this.logout();
        return EMPTY;
      }
    } else if(this.refreshObservable) {
      return this.refreshObservable.pipe(
        switchMap((response: any) => {
          console.log('token is refreshed, try request again');

          return next.handle(this.addTokenHeader(request, response.access_token));
        }),
        catchError((err) => {
          if(err.status === 401 || err.status === 403) {
            this.logout();
            return throwError(() => new Error(err));
          }
        })
      );
    }
    this.logout();
    return EMPTY;
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }

  private logout() {
    if(this.authService) {
      this.authService.logout();
    }
    this.router.navigateByUrl('/collection/user/login');
  }
}
