import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Playlist, SimpleTuneModel } from 'bandon-shared';
import { LibraryService } from 'src/app/services/library.service';
import { CachedImgSource, CachedImageComponent } from '../../general/cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { LocalFile } from 'src/app/shared/interfaces/local-file';
import { IonicModule } from '@ionic/angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-playlist-icon',
    templateUrl: './playlist-icon.component.html',
    styleUrls: ['./playlist-icon.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IonicModule,
        CachedImageComponent,
    ],
})
export class PlaylistIconComponent  implements OnInit, OnChanges {

  @Input() playlist: Playlist;
  @Input() showNewPlaylistIcon = false;

  playlistImgSrc: CachedImgSource[] = [];
  customPicture: LocalFile | undefined;

  constructor(
    private libraryService: LibraryService,
    private authService: AuthenticationService,
    private httpClient: HttpClient
  ) { }

/*  get showNewPlaylistIcon(): boolean {
    if(this.playlist && this.playlist.id===-1) {
      return true;
    }
    return false;
  }*/

/*  get playlistImgSrc(): CachedImgSource[] {
    if(this.playlist && this.playlist.tunes && this.playlist.tunes.length>0) {
      const out: CachedImgSource[] = [];
      const usedImgIds: number[] = [];
      for (const plTune of this.playlist.tunes) {
        const tune = this.getTune(plTune.tune_id);
        if(tune && usedImgIds.indexOf(tune.picture.id)===-1) {
          const token = 'Bearer '+environment.apiKey;

          out.push({ url: environment.apiURL+'/photoImg/'+tune.picture.id, path: this.getTunePath(tune), token });
          usedImgIds.push(tune.picture.id);
        }
      }
      return out;
    }
    return [{ url: '', path: '', token: '' }];
  }*/

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateImgSrc();
  }


  getTune(tuneid: number): SimpleTuneModel {
    return this.libraryService.getSimpleTune(tuneid);
  }

  getTunePath(tune: SimpleTuneModel): string {
    if(tune && tune.picture) {
      return tune.picture.path;
    }
    return '';
  }

  updateImgSrc() {
    this.playlistImgSrc.length = 0;
    if(this.playlist && this.playlist.img) {
      this.playlistImgSrc.push({
        url: environment.apiURL+`/playlists/${this.playlist.id}/img`,
        path: this.playlist.img,
        token: this.authService.getIDToken()
      });
    } else if(this.playlist && this.playlist.tunes && this.playlist.tunes.length>0) {
        const usedImgIds: number[] = [];
        for (const plTune of this.playlist.tunes) {
          const tune = this.getTune(plTune.tune_id);
          if(tune && usedImgIds.indexOf(tune.picture.id)===-1) {
            const token = 'Bearer '+environment.apiKey;

            this.playlistImgSrc.push({ url: environment.apiURL+'/photoImg/'+tune.picture.id, path: this.getTunePath(tune), token });
            usedImgIds.push(tune.picture.id);
          }
        }
    } else {
      this.playlistImgSrc.push({ url: '', path: '', token: '' });
    }
  }

}
