import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UserModel } from 'bandon-shared';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FcmTokensService {
  httpClient = inject(HttpClient)
  authService = inject(AuthenticationService)

  constructor() {
  }

  storeToken(user: UserModel, token: string) {
    if(this.authService.user) {
      const data = {
        userid: user.uid,
        token
      };

      const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
      this.httpClient.put<any>(environment.apiURL+`/usertokens`, data, {headers})
        .subscribe( {
          next: response => {
            console.log(response)
          },
          error: err => {
            console.error(err)
          }
        });
    }
  }

  deleteToken(token: string) {
    if(this.authService.user) {
      const data = {
        token
      };

      const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
      this.httpClient.delete<any>(environment.apiURL+`/usertokens`, {headers: headers, params: data})
        .subscribe( {
          next: response => {
            console.log(response)
          },
          error: err => {
            console.error(err)
          }
        });
    }
  }

  getUserTokens(user: UserModel) {

  }
}
