<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start"(click)="dismiss()">
      <ion-icon name="close" class="close-icon" color="primary" ariaLabel="{{ 'PLAYER.CLOSE' | translate }}"></ion-icon>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    @if(showSharing) {
      <ion-buttons slot="end" (click)="share()">
        <ion-icon name="share-outline" size="large" color="primary" ariaLabel="{{ 'PLAYER.SHARE' | translate }}"></ion-icon>
      </ion-buttons>
    }
  </ion-toolbar>
</ion-header>
<ion-content>
  <ngx-extended-pdf-viewer [src]="uri"
    [showDownloadButton]="false"
    [showToolbar]="false"
    [showSidebarButton]="false"
    [showFindButton]="false"
    [showPagingButtons]="false"
    [showDrawEditor]="false"
    [showTextEditor]="false"
    [showZoomButtons]="true"
    [showPresentationModeButton]="false"
    [showOpenFileButton]="false"
    [showPrintButton]="false"
    [showDownloadButton]="false"
    [showSecondaryToolbarButton]="false"
    [showRotateButton]="false"
    [showHandToolButton]="false"
    [showScrollingButton]="false"
    [showSpreadButton]="false"
    [showPropertiesButton]="false"
    [showBorders]="false"
    [backgroundColor]="backgroundColor"
    [(zoom)]="zoomSetting"
    [rotation]="0"
    (currentZoomFactor)="updateZoomFactor($event)"
    (pagesLoaded)="onPagesLoaded()">
  </ngx-extended-pdf-viewer>
</ion-content>
<ion-footer *ngIf="isWeb" >
  <ion-row>
    <ion-col size="4" class="ion-text-center">
      <ion-button fill="clear" color="primary" (click)="zoomOut()">
        <ion-icon name="remove"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size="4" class="ion-text-center" (click)="resetZoom()">
      <ion-button fill="clear" color="primary">
        1:1
      </ion-button>
    </ion-col>
    <ion-col size="4" class="ion-text-center">
      <ion-button fill="clear" color="primary" (click)="zoomIn()">
        <ion-icon name="add"></ion-icon>
        </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
