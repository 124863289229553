  <div class="page-container">
    <!-- welcome text-->
    <div class="welcome-container">
      <img src="assets/Band-On_Logo_weiss.png" class="band-on-logo" ariaLabel="{{ 'LOGIN.LOGO' | translate }}">
    </div>

    <!-- form -->

    <div class="form-container ion-text-center no-connection-alert ion-padding" *ngIf="!isConnected">
      <ion-text>{{ 'LOGIN.NOCONNECTION' | translate }}</ion-text><br>
      <ion-text>{{ 'LOGIN.NEEDSCONNECTION' | translate }}</ion-text>
    </div>

    <div class="form-container" *ngIf="isConnected" >
      <form (ngSubmit)="login()">
        <ion-input appAutofill appLowercase
            placeholder="{{ 'LOGIN.EMAIL' | translate }}"
            name="email"
            autocomplete="email"
            clearInput="true"
            type="email"
            required email
            [(ngModel)]="email"
            ariaLabel="{{ 'LOGIN.EMAIL' | translate }}">
        </ion-input>
        <app-show-hide-password>
          <ion-input appAutofill
            placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
            name="password"
            autocomplete="current-password"
            autocapitalize="off"
            type="password"
            required
            [(ngModel)]="password"
            ariaLabel="{{ 'LOGIN.PASSWORD' | translate }}"
            >
          </ion-input>
        </app-show-hide-password>

        <ion-button type="submit" strong="true" expand="block" color="secondary" [disabled]="!isSubmitEnabled">
          {{ 'LOGIN.SIGNIN' | translate }}
        </ion-button>
        <div class="forgot-password-container ion-padding" routerLink="/collection/user/reset-password" role="button">
          <ion-text>
            <span>{{ 'LOGIN.PWDFORGOT' | translate }}</span>
          </ion-text>
        </div>
      </form>
    </div>

    <!-- alternate login options -->
    <div class="alternate-container" *ngIf="isConnected">
      <div class="horizontal-line"></div>
      <div class="or-container ion-padding" *ngIf="!isAndroid">
        {{ 'LOGIN.ORCONTINUEWITH' | translate }}
      </div>

      <ion-grid>
        <ion-row>
          <ion-col width="6">
            <ion-button expand="block" mode="ios" (click)="loginWithApple()" color="secondary" class="button-margin-right"
                *ngIf="!isAndroid" >
              <ion-icon name="logo-apple"></ion-icon>
              <ion-text slot="end">Login mit <br>Apple</ion-text>
            </ion-button>
          </ion-col>
          <ion-col width="6">
            <ion-button expand="block" mode="ios" (click)="loginWithGoogle()" color="secondary" class="button-margin-left">
              <ion-icon name="logo-google" slot="start"></ion-icon>
              <ion-text slot="end">Login mit <br>Google</ion-text>
            </ion-button>
          </ion-col>
<!--          <ion-col>
            <ion-button expand="block" mode="ios">
              <ion-icon name="logo-facebook"></ion-icon>
            </ion-button>
          </ion-col>-->
        </ion-row>
        <ion-row *ngIf="isWeb" >
          <ion-col>
            <div class="allow-popup">
              <ion-text >
                {{ 'LOGIN.ALLOWPOPUPS' | translate }}
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <div class="register-prompt" *ngIf="isConnected">
        <ion-button expand="block" color="secondary" routerLink="/collection/user/register" role="button">
          {{ 'LOGIN.REGISTERNOW' | translate }}
        </ion-button>
      </div>
    </div>

  </div>
