<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="close" color="primary" class="close-icon" ariaLabel="{{ 'PLAYLISTS.CLOSE' | translate }}"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">
      {{ 'PLAYLISTS.CROPIMAGE' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <image-cropper
      #cropper
      [imageURL]="image"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      [resizeToWidth]="1000"
      [hideResizeSquares]="isMobile"
      [autoCrop]="false"
      format="png"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      (imageCropped)="imageCropped($event)"
  ></image-cropper>

</ion-content>
<ion-footer class="ion-text-center ion-padding-bottom">
  <ion-button (click)="cropImage()">{{ 'PLAYLISTS.SAVE' | translate }}</ion-button>
</ion-footer>
