import { Component, OnInit } from '@angular/core';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-offline-header',
    templateUrl: './offline-header.component.html',
    styleUrls: ['./offline-header.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule],
})
export class OfflineHeaderComponent  implements OnInit {

  constructor(private connServ: ConnectivityService) { }

  ngOnInit() {}

  get isOnline(): boolean {
    return this.connServ.isConnected();
  }

  get isSlow(): boolean {
    return this.connServ.isSlowConnection;
  }

  get isTestserver(): boolean {
    return environment.apiURL.includes('test');
  }

}
