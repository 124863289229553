import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Collection } from 'bandon-shared';
import { CachedImgSource, CachedImageComponent } from '../cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { Router, RouterLink } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';

@Component({
    selector: 'app-collection-item',
    templateUrl: './collection-item.component.html',
    styleUrls: ['./collection-item.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        RouterLink,
        CachedImageComponent,
    ],
})
export class CollectionItemComponent  implements OnInit, OnDestroy {

  @Input() collection: Collection;
  @Input() routerLink: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private bandonTranslations: BandONTranslationsService
  ) { }

  get collectionImgSrc(): CachedImgSource {
    if(this.collection && this.collection.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+this.collection.picture.id, path: this.collecitonImgPath, token };
    }
    return { url: '', path: '', token: '' };
  }

  get collecitonImgPath(): string {
    if(this.collection && this.collection.picture) {
      return this.collection.picture.path;
    }
    return '';
  }

  get designation(): string{
    return this.bandonTranslations.getCollectionTitle(this.collection);
  }

  get description(): string {
    return this.bandonTranslations.getCollectionDesc(this.collection);
  }

  get ariaText(): string {
    if(this.collection) {
      return this.bandonTranslations.getCollectionDescription(this.collection);
    }
    return this.translate.instant('PLAYER.NOINFORMATION');
  }


/*  get routerLink(): string {
    return `/collection/library/collection/${this.collection.id}`;
  }*/


  ngOnInit() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}
