import { registerLocaleData } from '@angular/common';
import { AppLoaderService } from './services/app-loader.service';
import { HttpClient } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import localeEn from '@angular/common/locales/en'
import localeDe from '@angular/common/locales/de'

registerLocaleData(localeEn)
registerLocaleData(localeDe)

// AoT requires an exported function for factories
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function serviceLoader(appLoader: AppLoaderService) {
  return () => appLoader.init();
}


