import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { IonInput, IonicModule } from '@ionic/angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-show-hide-password',
    templateUrl: './show-hide-password.component.html',
    styleUrls: ['./show-hide-password.component.scss'],
    standalone: true,
    imports: [NgIf, IonicModule],
})
export class ShowHidePasswordComponent  implements OnInit {
  @Input() color: "primary" | "secondary" | "light" = "secondary";

  @ContentChild(IonInput) input: IonInput;

  showPassword = false;

  constructor() { }

  ngOnInit() {}

  toggleShow() {
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? 'text' : 'password';
  }
}
