import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-level-icon',
    templateUrl: './level-icon.component.html',
    styleUrls: ['./level-icon.component.scss'],
    standalone: true,
    imports: [NgIf, IonicModule],
})
export class LevelIconComponent  implements OnInit {

  @Input() level: number;
  @Input() hasLevels: boolean;

  constructor() { }

  ngOnInit() {}

  getLevelColor(colorLevel: number): string {
    if(colorLevel<=this.level) {
      return 'primary';
    }
    return 'bright';
  }
}
