<!--<ion-toolbar class="offline-toolbar ion-text-center" *ngIf="!isOnline">
  {{ 'COLLECTION.OFFLINEMODE' | translate }}
</ion-toolbar>-->
<div class="ion-text-center offline-toolbar" *ngIf="!isOnline">
  {{ 'COLLECTION.OFFLINEMODE' | translate }}
</div>
<div class="ion-text-center slowCon-toolbar" *ngIf="isOnline && isSlow">
  {{ 'COLLECTION.SLOWCONN' | translate }}
</div>
<div class="ion-text-center testserver-toolbar" *ngIf="isOnline && !isSlow && isTestserver">
  Testserver
</div>
