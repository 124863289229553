import { Injectable } from '@angular/core';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  id: DeviceId | undefined;
  device: DeviceInfo | undefined;

  constructor() {
  }

  async init() {
    await this.getDeviceInfo();
  }

  async getDeviceInfo() {
    this.id = await Device.getId();
    this.device = await Device.getInfo();
  }
}
