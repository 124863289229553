import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sheet } from 'bandon-shared';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { SheetViewerComponent } from 'src/app/components/general/sheet-viewer/sheet-viewer.component';

@Injectable({
  providedIn: 'root'
})
export class SheetsService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private modalController: ModalController
  ) { }

  get isWeb() {
    return Capacitor.getPlatform() === 'web';
  }

  get isMacOS() {

    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('macintosh');
  }

  loadSheet(title: string, sheet: Sheet, previewSheet = false) {
    this.download(title, sheet, previewSheet);
  }

  download(title: string, sheet: Sheet, previewSheet) {
    const headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());

    this.httpClient.get(environment.apiURL+'/sheetFile/'+sheet.id, {headers, responseType: 'blob'})
    .subscribe({
      next: async resp => {
        if (resp) {
          const base64Data = await this.convertBlobToBase64(resp) as string;

          // Extract the parent folder from the sheet path
          const parentFolderPath = `${environment.sheetsCacheFolder}/${sheet.path}`.split('/').slice(0, -1).join('/');

          Filesystem.readdir({
            directory: Directory.Library,
            path: parentFolderPath
          })
          .then(() => {
            this.saveFile(title, base64Data, sheet, previewSheet);
          })
          .catch(() => {
            Filesystem.mkdir({
              directory: Directory.Library,
              path: parentFolderPath,
              recursive: true
            }).then(() => {
              this.saveFile(title, base64Data, sheet, previewSheet);
            });
          });
        }
      }
    });
  }

  async saveFile(title: string, base64Data, sheet: Sheet, previewSheet) {
    await Filesystem.writeFile({
      path: `${environment.sheetsCacheFolder}/${sheet.path}`,
      data: base64Data,
      directory: Directory.Library
    });

    Filesystem.readFile({
      directory: Directory.Library,
      path: `${environment.sheetsCacheFolder}/${sheet.path}`
    }).then(async file => {
      const uri = (await Filesystem.getUri({path: `${environment.sheetsCacheFolder}/${sheet.path}`, directory: Directory.Library})).uri;
      if(previewSheet) {
        this.previewFile(title, file.data, uri);
      } else {
        this.openFile(file.data, uri);
      }
    });
  }

  async openFile(data, path: string) {
    const mimeType = 'application/pdf';

    if(!this.isWeb/* && !this.isMacOS*/) {
      FileOpener.open({ filePath: path, contentType: mimeType, openWithDefault: true})
      .catch(error => console.log('Error opening file', error));
/*    } else if(this.isMacOS) {
      console.log('Platform is Mac OS');
      //TODO: find a solution*/
    } else {
      const blob = this.dataURItoBlob(data);
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    }
  }

  async previewFile(title: string, data, path: string) {
    const blob = this.dataURItoBlob(data);
    const fileURL = window.URL.createObjectURL(blob);
    const modal = await this.modalController.create({
      component: SheetViewerComponent,
      componentProps: {
        title, data, uri: fileURL, blob: blob
      },
      cssClass: "fullscreen"
    });
    modal.present();
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf',});
    return blob;
  }

  private getMimetype(name) {
    if (name.indexof('pdf') >= 0) {
      return 'application/pdf';
    }
  }
}
