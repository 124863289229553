<ion-app [lang]="language">
<!--  <ion-menu contentId="main-content" type="overlay" [disabled]="!isAuth" *ngIf="showMenu" side="end">

    <ion-content>
      <app-user-menu [isAuth]="isAuth"></app-user-menu>
    </ion-content>
  </ion-menu>-->

  <ion-router-outlet id="main-content" [swipeGesture]="false"></ion-router-outlet>
</ion-app>
