import { switchMap, take } from 'rxjs/operators';
import { CachingService } from './caching/caching.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject, tap, from } from 'rxjs';

import { ConnectivityService } from './connectivity/connectivity.service';
import { AuthenticationService } from './auth/authentication.service';
import { Musician, SessionModel, SimpleTuneModel, Speed, TrackFile, Tune, Collection, SimpleCollection, Playlist } from 'bandon-shared';
import { AudioEngine } from '@musicdose/audioengine';
import { StoreService } from './store/store.service';
import { BandONTranslationsService } from './languages/band-ontranslations.service';

const VALIDTIME = 15*60; //seconds

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  public demoCollections: SimpleCollection[] = [];
  public collectionsBehaviour = new BehaviorSubject<Collection[]>([]);
  public collections$ = this.collectionsBehaviour.asObservable();
  public userCollectionsBehaviour = new BehaviorSubject<number[]>([]);
  public userCollections$ = this.userCollectionsBehaviour.asObservable();
  public tunesBehaviour = new BehaviorSubject<SimpleTuneModel[]>([]);
  public tunes$ = this.tunesBehaviour.asObservable();

  public demoTunes: SimpleTuneModel[] = [];
  public userTunesBehaviour = new BehaviorSubject<number[]>([]);
  public userTunes$ = this.userCollectionsBehaviour.asObservable();

  public playlistsBehaviour = new BehaviorSubject<Playlist[]>([]);
  public playlists$ = this.playlistsBehaviour.asObservable();

  public tracks: TrackFile[] = [];
  public speeds: Speed[] = [];
  public tracksBehaviour = new BehaviorSubject<TrackFile[]>([]);
  public tracks$ = this.tracksBehaviour.asObservable();

  tuneList: SimpleTuneModel[] = [];
  tunes: Tune[]= [];

  isWaitingForAudioEngine = false;

//  private collectionList: SimpleCollection[] = [];
  private collections: SimpleCollection[] = [];
  private playlists: Playlist[] = [];
  private libraryUrl = environment.apiURL+'/tunes';
  private isOnline = false;
  private isAuth = false;

  private downloadStates = new Map<number, string>();
  private dSUnknownTunes: SimpleTuneModel[] = [];
  private dsUpdatingTunes: SimpleTuneModel[] = [];

  constructor(
      private http: HttpClient,
      private connectivityService: ConnectivityService,
      private authService: AuthenticationService,
      private cachingService: CachingService,
      private bandonTranslations: BandONTranslationsService) {
  }

  async init() {
    this.connectivityService.appIsOnline$.subscribe(online => {
      this.isOnline = online;
      this.loadSpeedsFromServer(false);
      this.loadTracksFromServer(false);
//      this.loadDataFromServer();
    });
    this.authService.isAuthenticated.subscribe(auth => {
      if (auth===this.isAuth) {
        return;
      }

      this.isAuth = auth;

//      this.loadDataFromServer(true);
    });
    this.authService.user$.subscribe(user => {
      this.loadSpeedsFromServer(true);
      this.loadTracksFromServer(true);
      this.loadDataFromServer(true);
    });
  }

  public refreshCaches() {
    //    this.cachingService.clearCachedImages();
    //    this.cachingService.clearCachedData();
    this.cachingService.invalidateCachedData();
    this.cachingService.clearCachedImages();
    this.loadSpeedsFromServer(true);
    this.loadTracksFromServer(true);
    this.loadDataFromServer(true);
  }

  async loadDataFromServer(forceRefresh = false) {
    this.loadTunesFromServer(forceRefresh);
    this.loadCollectionsFromServer(forceRefresh);
    this.loadUserLibraryFromServer(forceRefresh);
    this.loadPlaylistsFromServer(forceRefresh);

    if(forceRefresh) {
      this.downloadStates.clear();
    }
    //TODO: clear cached download state
  }

  getTunes(): Observable<SimpleTuneModel[]> {
    return of(this.tuneList);
  }

/*  getCollections(): Observable<Collection[]> {
    return of(this.collections);
  }*/

  getCollection(id: number): Observable<Collection> {
    return this.getData(environment.apiURL+'/collections/'+id, false);
  }

  getPlaylist(id: number, forceRefresh=false): Observable<Playlist> {
    return this.getData(environment.apiURL+'/playlists/'+id, forceRefresh);
  }

  getCollectionToTune(tune: Tune): Collection {
    if(this.collections) {
      this.collections.forEach(c => {
        if(c.tunes) {
          const tuneIds = c.tunes.split(',');
          tuneIds.forEach(t => {
            if(Number(t)===tune.id) {
              return c;
            }
          });
        }
      });
    }
    return null;
  }

  getSession(id: number): Observable<SessionModel> {
    return this.getData(environment.apiURL+'/sessions/'+id, false);
  }

  getMusician(id: number): Observable<Musician> {
    return this.getData(environment.apiURL+'/musicians/'+id, false);
  }

  getSimpleTune(tuneId: number): SimpleTuneModel {
    // eslint-disable-next-line eqeqeq
    const result = this.tuneList.filter(t => t.id == tuneId);
    if( result.length > 0 ) {
      return result[0];
    }
    return null;
  }

  loadTunesFromServer(forceRefresh: boolean) {
    return this.getData(this.libraryUrl, forceRefresh)
    .pipe(take(1))
    .subscribe(result => {
      if(result) {
        this.tuneList.length = 0;
        this.tuneList.push(...result);
        this.tuneList = this.tuneList.sort((e1, e2) => e1.title>e2.title ? 1: -1);
        this.demoTunes.length = 0;
        this.tuneList.forEach(t => {
          t.isUpdatingDownloadState=false;
          if(t.isdemo) {
            this.demoTunes.push(t);
          }
        });
        this.tunesBehaviour.next(this.tuneList);
//          this.updateDownloadedState(forceRefresh);
      }
    });
  }

  loadCollectionsFromServer(forceRefresh: boolean) {
    this.getData(environment.apiURL+'/collections', forceRefresh)
    .pipe(take(1))
    .subscribe(result => {
      if(result) {
        this.collections.length = 0;
        this.collections.push(...result);
        this.collectionsBehaviour.next(result);

        this.demoCollections.length = 0;
        this.collections.forEach(c => {
          if(c.isdemo) {
            this.demoCollections.push(c);
          }
        });

      }
    });
  }

  loadUserLibraryFromServer(forceRefresh: boolean) {
    if (this.isAuth) {
      this.getData(`${environment.apiURL}/users/${this.authService.getUserID()}/library`, forceRefresh)
      .pipe(take(1))
      .subscribe(result => {
        if(result) {
          const userTunes = [];
          const userCollections = [];
          for(const item of result) {
            if(item.tuneid && userTunes.indexOf(item.tuneid)<0) {
              userTunes.push(item.tuneid);
            }
            if(item.collectionid && userCollections.indexOf(item.collectionid)<0) {
              userCollections.push(item.collectionid);
            }
          }
          this.userTunesBehaviour.next(userTunes);
          this.userCollectionsBehaviour.next(userCollections);
        } else {
          this.userTunesBehaviour.next([]);
          this.userCollectionsBehaviour.next([]);
        }
      });
    } else {
      this.userTunesBehaviour.next([]);
      this.userCollectionsBehaviour.next([]);
    }
  }

  loadTuneFromServer(tuneId: number, forceRefresh: boolean): Observable<any> {
    return this.getData(environment.apiURL+'/tunes/'+tuneId, forceRefresh);
  }

  loadTracksFromServer(forceRefresh: boolean) {
    this.getData(environment.apiURL+'/trackfiles', forceRefresh)
    .pipe(take(1))
    .subscribe(result => {
      if(result) {
        this.tracks.length = 0;
        this.tracks.push(...result);
        this.tracksBehaviour.next(this.tracks);
        this.dSUnknownTunes.forEach(tune => {
          this.refreshTuneDownloadedState(tune);
        });
//        this.updateDownloadedState(forceRefresh);
      }
    });
  }

  loadSpeedsFromServer(forceRefresh: boolean) {
    this.getData(environment.apiURL+'/tunespeeds', forceRefresh)
    .pipe(take(1))
    .subscribe(result => {
      if(result) {
        this.speeds.length = 0;
        this.speeds.push(...result);
      }
    });
  }

  loadPlaylistsFromServer(forceRefresh: boolean) {
    if(this.isAuth) {
      this.getData(environment.apiURL+'/playlists', forceRefresh)
      .pipe(take(1))
      .subscribe(result => {
        if(result) {
          this.playlists.length = 0;
          this.playlists.push(...result);
          this.playlistsBehaviour.next(result);
        }
      });
    }
  }

  public getDownloadState(tuneID: number): string {
    if(this.downloadStates.has(tuneID)) {
//      console.log('get Download state: ', tuneID, this.downloadStates.get(tuneID));
      return this.downloadStates.get(tuneID);
    }
    this.updateTuneDownloadedState(tuneID, false);
    return 'unknown';
  }

  public async updateTuneDownloadedState(tuneID: number, forceRefresh: boolean) {

    const tune = this.tuneList.find(t => t.id===tuneID);

    if(!tune || this.dsUpdatingTunes.filter(t => t.id===tuneID).length>0 /*tune.isUpdatingDownloadState*/) {
      return;
    }
    tune.isUpdatingDownloadState = true;
//    if(this.dsUpdatingTunes.filter(t => t.id===tuneID).length===0)
    this.dsUpdatingTunes.push(tune);

    if(forceRefresh) {
      return this.refreshTuneDownloadedState(tune);
    } else {

      const storedValue = await this.cachingService.getCachedDownloadState(tuneID);
      if (!storedValue) {
        return this.refreshTuneDownloadedState(tune);
      } else {
        this.downloadStates.set(tune.id, storedValue);
        if(storedValue === 'unknown' && this.dSUnknownTunes.filter(t=>t.id===tune.id).length===0) {
          this.dSUnknownTunes.push(tune);
        }
        tune.isUpdatingDownloadState = false;
        if(this.dsUpdatingTunes.filter(t => t.id===tuneID).length>0) {
          const index = this.dsUpdatingTunes.indexOf(this.dsUpdatingTunes.find(t=>t.id===tuneID));
          this.dsUpdatingTunes.splice(index, 1);
        }


        return of(storedValue);
      }

/*      return storedValue.pipe(
        switchMap(result => {
          if (!result) {
            console.log('Update Tune Downloaded State, call refresh function ');
            return this.refreshTuneDownloadedState(tune);
          } else {
            return of(result);
          }
        })
      );*/
    }
  }

  public filterTunes(tunes: SimpleTuneModel[], text: string): SimpleTuneModel[] {
    if(!text) {
        return tunes;
    }
    return tunes.filter((tune: SimpleTuneModel) => {
      const words = text.toLowerCase().split(' ');
      let match = true;
      let firstEntry = true;
      tune.searchNote = '';
      words.forEach(word => {
        if(word) {
          const des = this.bandonTranslations.getTuneTitle(tune)+' '+tune.speeds+' '+tune.tonalities;
          const basicMatch = des.toLowerCase().indexOf(word) > -1;

          const instrText = this.searchInField('Instrumente', tune.instruments, word);
          const groupText = this.searchInField('Section', tune.instrumentgroups, word);
          const styleText = this.searchInField('Stile', tune.styles, word);
          //TODO: search for Musicians

          const strings = [ styleText, instrText, groupText];
          if(instrText || styleText || groupText) {
            strings.forEach( s => {
              if(s && tune.searchNote.indexOf(s)===-1) {
                if(!firstEntry) {
                  tune.searchNote += ', ';
                }
                tune.searchNote += s;
                firstEntry = false;
              }
            });
          }
          if(!(basicMatch || instrText || styleText || groupText)) {
            match = false;
          }
        }
      });
      return match;
    });
  }

  public searchInField(designation: string, field: string, text: string): string {
    let instrMatch = false;
    let instrText = '';
    const containsText = 'Enthält';
    if(field && text.length>2) {
      instrMatch = field.toLowerCase().indexOf(text.toLowerCase()) > -1;
      if(instrMatch) { //TODO: translate
        const instruments = field.split(',');
        instrText = `${containsText} ${designation}: `;
        let firstEntry = true;
        instruments.forEach(i => {
          if(i.toLowerCase().indexOf(text.toLowerCase())> -1) {
            if(!firstEntry) {
              instrText += ', ';
            }
            instrText += i;
            firstEntry = false;
          }
        });
      }
    }
    return instrText;
  }

  private getData(url, forceRefresh: boolean): Observable<any> {
    if(!this.isOnline) {
      return from(this.cachingService.getCachedRequest(url));
    }

    if(forceRefresh) {
      return this.callAndCache(url);
    } else {
      const storedValue = from(this.cachingService.getCachedRequest(url));
      return storedValue.pipe(
        switchMap(result => {
          if (!result) {
            // make an api call
            return this.callAndCache(url);
          } else {
            return of(result);
          }
        })
      );
    }
  }

  private callAndCache(url): Observable<any> {
    let headers = new HttpHeaders();
    if(!this.isAuth) {
      headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    } else {
//      console.log('get Tracks from Server, ', url);
      headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    }
    return this.http.get(url, {headers}).pipe(
      tap(res => {
        this.cachingService.cacheRequest(url, res);
      })
    );
  }

/*  private async updateDownloadedState(forceRefresh: boolean) {

    if(this.tuneList && this.tuneList.length>0 && this.tracks && this.tracks.length>0)
    {
      for (const tune of this.tuneList) {
        this.updateTuneDownloadedState(tune.id, forceRefresh);
      }
    }
  }*/

  private async refreshTuneDownloadedState(tune: SimpleTuneModel) {
    const tracks = tune.tracks.split(',');
    let isDownloaded = true;
    let isChecked = false;

    for (const track of tracks) {
      const t = this.tracks.find(searchTrack => searchTrack.id===Number(track));
      if(t) {
        this.isWaitingForAudioEngine = true;
        const result = await AudioEngine.getIsTrackDownloaded({
          trackID: t.id,
          path : t.path,
          fileMD5: t.filechecksum,
        });
        this.isWaitingForAudioEngine = false;
        isDownloaded = isDownloaded && result.downloaded;
        isChecked = true;
      }
    }
    if(isDownloaded && isChecked) {
      this.cachingService.cacheDownloadedState(tune.id, 'downloaded');
      this.downloadStates.set(tune.id, 'downloaded');
      this.dSUnknownTunes.splice(this.dSUnknownTunes.indexOf(tune), 1);
    } else if(isChecked) {
      this.cachingService.cacheDownloadedState(tune.id, 'cloud');
      this.downloadStates.set(tune.id, 'cloud');
      this.dSUnknownTunes.splice(this.dSUnknownTunes.indexOf(tune), 1);
    } else {
      this.cachingService.cacheDownloadedState(tune.id, 'unknown');
      this.downloadStates.delete(tune.id);
      if(this.dSUnknownTunes.filter(t=>t.id===tune.id).length===0) {
        this.dSUnknownTunes.push(tune);
      }
    }
    tune.isUpdatingDownloadState = false;
    if(this.dsUpdatingTunes.filter(t => t.id===tune.id).length>0) {
      const index = this.dsUpdatingTunes.indexOf(this.dsUpdatingTunes.find(t=>t.id===tune.id));
      this.dsUpdatingTunes.splice(index, 1);
    }
//    return this.cachingService.getCachedDownloadState(tune.id);
  }

}
