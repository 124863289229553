import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputChangeEventDetail, IonicModule, ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Speed } from 'bandon-shared';
import { Options, NgxSliderModule } from 'ngx-slider-v2';
import { Subject, min, takeUntil } from 'rxjs';
import { LoadingPage } from 'src/app/pages/modal/loading/loading/loading.page';
import { AudioService } from 'src/app/services/audio/audio.service';
import { TuneInfoService } from 'src/app/services/audio/tune-info.service';

@Component({
    selector: 'app-tempo-slider',
    templateUrl: './tempo-slider.component.html',
    styleUrls: ['./tempo-slider.component.scss'],
    standalone: true,
    imports: [NgxSliderModule, IonicModule, FormsModule],
})
export class TempoSliderComponent  implements OnInit, OnChanges, OnDestroy {
  translateService = inject(TranslateService)
  audioService = inject(AudioService)
  modalController = inject(ModalController)
  tuneInfoService = inject(TuneInfoService)

  @Input() ticks: number[] = []; // Define ticks as an input property
  @Input() minTempo: number = 0;
  @Input() maxTempo: number = 120;
  @Input() startValue: number = 100;

  @Output() onTempoChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() changeSpeed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('customSlider', { static: true }) customSlider: ElementRef;

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  value: number = 100;
  options: Options = {
    floor: this.minTempo,
    ceil: this.maxTempo,
    vertical: true,
    ticksArray: this.ticks,
    showSelectionBar: true,
    showTicksValues: true
  };

  private wasPlaying = false;

  private unsubscribe$ = new Subject<void>();

  private loading: HTMLIonModalElement = null;
  private loadingShowed = false;

  private tempoChanged = false;

  constructor(
    private popoverController: PopoverController
  ) {}

  get helperText(): string {
    return this.translateService.instant('PLAYER.SELECTTEMPO');
  }

  ngOnInit() {
    this.updateOptions();

    this.audioService.initCompleted$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async completed => {
      if(completed && this.tempoChanged) {
//        console.log('Init completed');
        this.dismissLoading();
        let newTimestamp = this.tuneInfoService.calcNewTimestamp(this.tuneInfoService.oldTimestampDef);
        if(newTimestamp !== undefined) {
          await this.audioService.setCurrentTime(newTimestamp);
          this.tuneInfoService.copyLoop();
          if(this.wasPlaying) {
            this.audioService.play();
          }
        } else {
          this.tuneInfoService.jumpToStart();
        }
        this.tuneInfoService.copyMix();
        this.onTempoChange.emit(this.value);
        this.tempoChanged = false;

/*        if(this.oldTimestamp) {
          const newTimestamp = this.stretchFactor * this.oldTimestamp;// + this.audioService.currentSpeed.start;
          await this.audioService.setCurrentTime(newTimestamp, false);
          if(this.wasPlaying) {
            this.audioService.play();
          }
          if(this.wasLooping) {
            this.audioService.setLoopTimes(this.oldLoopStart * this.stretchFactor, this.oldLoopEnd * this.stretchFactor);
          }
        } else if(this.oldTimestamp==0) {
          this.tuneInfoService.jumpToStart();
        }*/
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
//    this.updateOptions();
    this.value = this.startValue;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  tempoChange() {
//    this.manualRefresh.emit();
//    this.onTempoChange.emit(this.value);
  }

  dismiss() {
    this.popoverController.dismiss();
  }

  private updateOptions() {
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = this.minTempo;
    newOptions.ceil = this.maxTempo;
    newOptions.ticksArray = this.ticks;
    this.value = this.startValue;
    this.options = newOptions;
    this.manualRefresh.emit();
  }


  onChange(event: any) {
    this.value = event.target.value;
    this.setTempo(this.value);
//    this.onTempoChange.emit(this.value);
  }

  public calculateTickPosition(position: number): number {
    let out = ((position - this.minTempo) / (this.maxTempo - this.minTempo)) * 100;
    return out;
  }

  public calculateTickValuePosition(position: number): number {
    // Access the native element using ViewChild
    const element = this.customSlider.nativeElement;

    // Get the height of the element
    const sliderHeight = element.clientHeight;
    let out = ((position - this.minTempo) / (this.maxTempo - this.minTempo));
    return (sliderHeight-22)*out + 11;
  }


  getBackgroundColor(tick: number) {
    if(tick<=this.value) {
      return 'var(--ion-color-primary)';
    }
    return 'white';
  }

  async setTempo(value: number) {
    this.value = value;

    //Get closest recorded speed
    let minDiff = 1e8;
    let selectedSpeed = undefined;
    for(let s of this.audioService.speeds) {
      //TODO: set switch at 70% not at 50
      const diff = Math.abs(value - s.speed)
      if(diff < minDiff) {
        minDiff = diff;
        selectedSpeed = s;
      }
    }
    if(selectedSpeed.id !== this.audioService.currentSpeed.id) {
      await this.selectSpeed(selectedSpeed);
    } else {
      this.onTempoChange.emit(this.value);
    }
//    console.log(`Set tempo=${value}`)
  }

  setTempoEvent(event: any) {
    let tempo = Number(event.detail.value)
    if(tempo) {
      tempo = Math.min(this.maxTempo, tempo);
      tempo = Math.max(this.minTempo, tempo);
      this.setTempo(tempo);
    }
  }

  async selectSpeed(speed: Speed) {
    if(speed.id !== this.audioService.currentSpeed.id) {
      console.log('change tempo');
      this.tuneInfoService.saveOldSpeedData();
      this.wasPlaying = this.audioService.isPlaying;

      this.changeSpeed.emit();
      this.tempoChanged = true;
      const needsDownload = await this.audioService.selectSpeed(speed);
      if(needsDownload) {
        this.presentLoading();
      }
    }
  }

  async presentLoading() {
    if(!this.loadingShowed) {
      this.loadingShowed = true;
      await this.openTransparentModal();
    }
    if(!this.loadingShowed) {
      this.loading.dismiss();
    }
  }

  async openTransparentModal() {
    const modal = await this.modalController.create({
      component: LoadingPage,
      cssClass: 'transparent-modal',
      backdropDismiss: false,
      componentProps: {
        routeBack: false
      }
    });
    this.loading = modal;

    await modal.present();

    modal.onDidDismiss().then((data) => {
      if (data?.role && data.role == 'cancel') {
      }
    });
  }

  dismissLoading() {
    this.loadingShowed = false;
    if(this.loading) {
      this.loading.dismiss();
    }
  }
}
