<div class="button-container">
  @if(fileState==='cloud') {
    <ion-icon name="cloud-download-outline" color="primary"
      class="center" (click)="initDownload()" [attr.aria-label]="ariaDownload" role="button"></ion-icon>
  }
  @if(fileState==='downloaded') {
    <ion-icon name="arrow-down-circle" class="center downloaded-img"
      [id]="popoverTriggerID" (click)="showPopupMenu($event)" [attr.aria-label]="ariaIsDownloaded" role="button">
    </ion-icon>
    <ion-popover [isOpen]="isOpen" [dismissOnSelect]="true" backdropDismiss="true" class="remove-popover" (didDismiss)="isOpen=false" side="left" alignment="start" #popupmenu>
      <ng-template>
        <ion-content scrollY="false">
          <ion-list>
            <ion-item [button]="true" [detail]="false" (click)="removeFiles()" class="remove-Item ion-text-nowrap">
              <ion-icon name="trash" class="remove-icon"></ion-icon>
              {{ 'COLLECTION.REMOVEDOWNLOAD' | translate }}
            </ion-item>
            <ion-item [button]="true" [detail]="false" (click)="dismiss()" class="ion-text-nowrap" style="height: 0;">
              {{ 'COLLECTION.CLOSE' | translate }}
            </ion-item>
          </ion-list>
        </ion-content>
      </ng-template>
    </ion-popover>
  }
  <circle-progress *ngIf="fileState==='downloading'"
      aria-label="Download fortschritt"
      aria-hidden="false"
      aria-valuemin="0"
      aria-valuemax="100"
      role="progressbar"
      [attr.aria-valuenow]="progress"
      [percent]="progress"
      [innerStrokeWidth]="3"
      [radius]="6"
      [outerStrokeWidth]="3"
      [space]="-3"
      class="center">
    </circle-progress>

</div>
