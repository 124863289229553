import { Injectable, inject } from '@angular/core';
import { CachingService } from '../caching/caching.service';
import { AuthenticationService } from '../auth/authentication.service';
import { BehaviorSubject, Observable, from, of, switchMap, tap } from 'rxjs';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Relationship } from 'bandon-shared';
import { BarcodeScanner, GoogleBarcodeScannerModuleInstallState } from '@capacitor-mlkit/barcode-scanning';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  connectivityService = inject(ConnectivityService)
  httpClient = inject(HttpClient)
  authService = inject(AuthenticationService)
  cachingService = inject(CachingService)

  public contactsBehaviour = new BehaviorSubject<Relationship[]>([]);
  public contacts$ = this.contactsBehaviour.asObservable();

  public _notificationCount = 0;

  public scannerAvailable = true;

  private isOnline = false;
  private isAuth = false;

  get notificationCount() {
    if(!this.isAuth) {
      return 0;
    }
    return this._notificationCount;
  }

  public notificationCountBehaviour = new BehaviorSubject<number>(0);
  public notificationCount$ = this.notificationCountBehaviour.asObservable();

  constructor() {
    if(Capacitor.getPlatform() === 'android') {
      BarcodeScanner.isGoogleBarcodeScannerModuleAvailable().then(availabe => {
        this.scannerAvailable = availabe.available;
        if(!availabe.available) {
          BarcodeScanner.installGoogleBarcodeScannerModule();
          BarcodeScanner.addListener('googleBarcodeScannerModuleInstallProgress', progress => {
            if(progress.state === GoogleBarcodeScannerModuleInstallState.COMPLETED) {
              this.scannerAvailable = true;
            }
          });
        }
      });
    }

    this.connectivityService.appIsOnline$.subscribe(online => {
      this.isOnline = online;
    });

    this.authService.isAuthenticated.subscribe(auth => {
      if (auth===this.isAuth) {
        return;
      }
      this.isAuth = auth;
      if(!this.isAuth) {
        this.notificationCountBehaviour.next(0)
      }
    });

    this.authService.user$.subscribe(() => {
      this.refreshContacts(true)
    })
  }

  public refreshContacts(forceRefresh = false) {
    const user = this.authService.user;
    if(user && this.isAuth) {
      this.getData(`${environment.apiURL}/users/${user.username}/contacts`, true)
        .subscribe({
          next: data => {
            this.contactsBehaviour.next(data);
            this._notificationCount = data.filter(c => c.state.designation === 'request' && c.username1 !== user.username).length;
            this.notificationCountBehaviour.next(this._notificationCount);
          }
        })
    } else {
      this.contactsBehaviour.next([]);
      this._notificationCount = 0;
    }
  }

  public getRelationship(username1: string, username2: string) {
    const out = this.contactsBehaviour.value.filter(r => {
      if(r.username1===username1 && r.username2===username2) {
        return true;
      } else if(r.username2===username1 && r.username1===username2) {
        return true;
      }
      return false;
    });
    if(out.length>0) {
      return out[0]
    }
    return undefined;
  }
  public hasContact(username1: string, username2: string) {
    return this.contactsBehaviour.value.filter(r => {
      if(r.username1===username1 && r.username2===username2) {
        return true;
      } else if(r.username2===username1 && r.username1===username2) {
        return true;
      }
      return false;
    }).length > 0;
  }

  private getData(url, forceRefresh: boolean): Observable<any> {
    if(!this.isOnline) {
      return from(this.cachingService.getCachedRequest(url));
    }

    if(forceRefresh) {
      return this.callAndCache(url);
    } else {
      const storedValue = from(this.cachingService.getCachedRequest(url));
      return storedValue.pipe(
        switchMap(result => {
          if (!result) {
            // make an api call
            return this.callAndCache(url);
          } else {
            return of(result);
          }
        })
      );
    }
  }

  private callAndCache(url): Observable<any> {
    let headers = new HttpHeaders();
    if(!this.isAuth) {
      headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    } else {
//      console.log('get Tracks from Server, ', url);
      headers = new HttpHeaders().set('Authorization', this.authService.getIDToken());
    }
    return this.httpClient.get(url, {headers}).pipe(
      tap(res => {
        this.cachingService.cacheRequest(url, res);
      })
    );
  }
}
