import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { NativeMarket } from "@capacitor-community/native-market";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  authService = inject(AuthenticationService)
  httpClient = inject(HttpClient)
  alertController = inject(AlertController)
  translate = inject(TranslateService)

  get isWeb(): boolean {
    return Capacitor.getPlatform() === 'web';
  }

  async checkForUpdate() {
    if(this.isWeb) {
      return;
    }

    const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
    const params = { version: environment.appVersion };
    this.httpClient.get<any>(environment.apiURL+"/app-version-check", {headers, params})
    .subscribe(
      (response) => {
        if(response.update_available) {
          let allowClose = true;
/*          if(response.breaking_changes) {
            allowClose = false;
          }*/
          this.presentAlert(this.translate.instant('MESSAGES.NEWVERSIONTITLE'),
          this.translate.instant('MESSAGES.NEWVERSIONMSG'), 'Download', allowClose)
        }
      }
    );
  }

  openAppStoreEntry() {
    if(!this.isWeb) {
      let appId = "id1673700582";
      if (Capacitor.getPlatform() === "android") {
        appId = "ch.pmtbmbh.bandon.app";
      }
      NativeMarket.openStoreListing({
        appId: appId,
      });
    }
  }

  async presentAlert(header, message, buttonText = '', allowClose = false) {
    const buttons = []

    if (buttonText != '') {
      buttons.push({
        text: buttonText,
        handler: () => {
          this.openAppStoreEntry()
        }
      })
    }

    if (allowClose) {
      buttons.push({
        text: this.translate.instant('MESSAGES.CANCEL'),
        role: 'cancel'
      })
    }

    const alert = await this.alertController.create({
      header,
      message,
      buttons,
      backdropDismiss: allowClose,
      cssClass: 'band-on-alert'
    });
    alert.present();
  }
}
