import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface LastPlayedTune {
  id?: string;
  tuneId: number;
  date: Date;
}

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(
    ) { }

/*  getLastPlayed() {
    const user = this.auth.currentUser;
    return this.afs
      .collection(`users/${user.uid}/lastPlayed/`)
      .snapshotChanges();
  }*/

/*  getLastPlayedById(tuneId: number): Observable<LastPlayedTune[]> {
    let user = this.auth.currentUser;
    const lastPlayedRef = this.afs.collection(`users/${user.uid}/lastPlayed/`);
    const userDocRef = collection(this.firestore, `users/${user.uid}/lastPlayed/`);
    return collectionData(userDocRef, { idField: 'id' }) as Observable<LastPlayedTune[]>;
  }*/

/*  async updateLastPlayed(tuneId: number) {
    const unsubscribe$ = new Subject<void>();

    const user = this.auth.currentUser;

    this.afs.collection(`users/${user.uid}/lastPlayed/`, ref => ref
        .where('tuneId', '==', tuneId)).snapshotChanges()
      .pipe(takeUntil(unsubscribe$))
      .subscribe(res => {
        unsubscribe$.next();
        unsubscribe$.complete();
      const played = res.map((e) => ({
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as LastPlayedTune)
        })).sort((e1, e2) => {
          if (e1.date > e2.date) {
            return -1;
          } else if (e1.date < e2.date) {
            return 1;
          }
          return 0;
        });

      if(played.length>0) {
        this.updateLastPlayedEntry(played[0].id);
      } else {
        this.addLastPlayedEntry(tuneId);
      }
    });
  }*/

/*  updateLastPlayedEntry(tuneId) {
    const user = this.auth.currentUser;
    return this.afs.collection(`users/${user.uid}/lastPlayed/`).doc(tuneId).update({
      date: new Date()
    });
  }*/

/*  addLastPlayedEntry(tuneId) {
    const user = this.auth.currentUser;
    return new Promise<any>((resolve, reject) => {
      this.afs
        .collection(`users/${user.uid}/lastPlayed/`)
        .add({
          tuneId,
          date: new Date()
        });
    });
  }*/


}
