@if(_src !== '') {
  <img [src]="_src" [class.contained-img]="imageFit==='contain'" [class.covered-img]="imageFit=='cover'" [ariaHidden]="isAriaHidden" [ariaLabel]="ariaInfo">
}
@else {
  <div [class.contained-img]="imageFit==='contain'" [class.covered-img]="imageFit=='cover'" [ariaHidden]="isAriaHidden">
    <div class="ion-text-center" *ngIf="spinner">
      <ion-spinner></ion-spinner>
    </div>
    <div *ngIf="!spinner">
      <img src="assets/Band-On_Logo_loading.png">
    </div>
  </div>
}

