import { Injectable, inject } from '@angular/core';
import { ContactsService } from '../social/contacts.service';
import { UserMessagesService } from '../social/user-messages.service';
import { Badge } from '@capawesome/capacitor-badge';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  contactsService = inject(ContactsService)
  messagesService = inject(UserMessagesService)

  contactsCount = 0;
  messagesCount = 0;

  constructor() {
    Badge.isSupported().then(support => {
      if(support) {
        Badge.checkPermissions().then(status => {
          if(status.display==='prompt' || status.display==='prompt-with-rationale') {
            Badge.requestPermissions();
          }
        })
        Badge.requestPermissions();
      }
    });

    this.contactsService.notificationCount$.subscribe(count => {
      this.contactsCount = count;
      this.updateBadge();
    });

    this.messagesService.notificationCount$.subscribe(count => {
      this.messagesCount = count;
      this.updateBadge();
    });
  }

  updateBadge() {
    Badge.set({ count: this.contactsCount + this.messagesCount })
  }
}
