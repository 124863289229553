import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { Musician, SimpleTuneModel } from 'bandon-shared';
import { CachedImgSource, CachedImageComponent } from '../../general/cached-image/cached-image.component';
import { environment } from 'src/environments/environment';
import { TuneSku } from 'src/app/shared/interfaces/tune-sku';
import { StoreService } from 'src/app/services/store/store.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { BandONTranslationsService } from 'src/app/services/languages/band-ontranslations.service';
import { LevelIconComponent } from '../../level-icon/level-icon.component';
import { NgIf, DecimalPipe, CurrencyPipe } from '@angular/common';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-tune-details',
    templateUrl: './tune-details.component.html',
    styleUrls: ['./tune-details.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        CachedImageComponent,
        NgIf,
        LevelIconComponent,
        DecimalPipe,
        CurrencyPipe,
        TranslateModule,
    ],
})
export class TuneDetailsComponent  implements OnInit {

  @Input() tuneSku: TuneSku;
  @Input() tune: SimpleTuneModel;

  constructor(
    private modalCtrl: ModalController,
    private storeService: StoreService,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private bandonTranslations: BandONTranslationsService,
    private connService: ConnectivityService
  ) {}

  get title() {
    if(this.tune) {
      return this.bandonTranslations.getTuneTitle(this.tune);
    }
    return '';
  }

  get coverText(): string{
    if(this.tune) {
      const translation = this.tune.translations.find(e => e.languageid===this.language);
      if(translation) {
        return translation.covertext;
      }
      return this.tune.covertext;
    }
    return '';
  }

  get language() {
    let language = this.translateService.currentLang;
    if(this.authService.user) {
      language = this.authService.user.language;
    }
    return language;
  }

  get price(): number {
    if(this.tuneSku) {
      return this.tuneSku.sku.product.price;
    }
    return 0;
  }

  get currencyCode(): string {
    if(this.tuneSku) {
      return this.tuneSku.sku.product.currencyCode;
    }
    return '';
  }

/*  get tune() {
    if(this.tuneSku) {
      return this.tuneSku.tune;
    }
    return null;
  }*/

  get tonalitiesString() {
    if(this.tune) {
      return `${ this.translateService.instant('PLAYER.TONALITY')}: ${ this.tune.tonalities }`;
    }
    return '';
  }

  get ariaTonalitiesString() {
    if(this.tune) {
      return this.bandonTranslations.getTonalityString(this.tune.tonalities)
    }
    return '';
  }

  get tempiString() {
    if(this.tune) {
      if(this.tune.speeds.split(',').length>1) {
        return `${this.translateService.instant('PLAYER.SPEEDS')}: ${ this.tune.speeds }`;
      }
      return `${this.translateService.instant('PLAYER.SPEED')}: ${ this.tune.speeds }`;
    }
    return '';
  }

  get hasLevels() {
    if(this.tune) {
      return this.tune.levelids.split(',').length>1;
    }
    return false;
  }

  get levelsString() {
    if(this.tune) {
      if(this.tune.levelids.split(',').length>1) {
        return this.translateService.instant('STORE.HASLEVELS');
      }
      return ``;
    }
    return '';
  }

  get isOnline(): boolean {
    return this.connService.isConnected();
  }

  get musiciansString(): String {
    if(this.tune) {
      return this.tune.musicians;
    }
    return '';
  }

  get ariaCovertext(): SafeHtml {
    // Create a temporary DOM element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = this.coverText;

    // Retrieve text content from the temporary element
    const textContent = tempElement.textContent || tempElement.innerText || '';

    // Clean up temporary element
    tempElement.remove();

    return textContent.trim();
  }

  ngOnInit(): void {

  }

  dismiss() {
    return this.modalCtrl.dismiss();
  }

  getTuneIconSrc(tune: SimpleTuneModel | undefined): CachedImgSource {
    if (tune && tune.picture) {
      const token = 'Bearer '+environment.apiKey;
      return { url: environment.apiURL+'/photoImg/'+tune.picture.id, path: tune.picture.path, token };
    }
    return { url: '', path: '', token: '' };
  }

  purchase() {
    this.storeService.purchaseTune(this.tuneSku);
  }

}
