import { TuneInfoService } from 'src/app/services/audio/tune-info.service';
import { AudioDownloadService } from 'src/app/services/audio/audio-download.service';
import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { AudioService } from 'src/app/services/audio/audio.service';
import { Subject, takeUntil } from 'rxjs';
import { NgZone } from '@angular/core';
import { Location, NgIf } from '@angular/common';
import { ModalController, IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.page.html',
    styleUrls: ['./loading.page.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgIf,
        TranslateModule,
    ],
})
export class LoadingPage implements OnInit, OnDestroy {

//  @Input() downloadProgress: number;
//  @Input() downloadSize: number;
  @Input() goToPlaylistID: number;
  @Input() routeBack = true;

  @Output() onCancel: EventEmitter<number> = new EventEmitter<number>();

  downloadProgress = 0;
  downloadSize = 0;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private audioService: AudioService,
    private audioDownloadService: AudioDownloadService,
    private ngZone: NgZone,
    private location: Location,
    private modalController: ModalController,
    private router: Router
  ) {
  }


/*  get downloadProgress(): number {
    return this.audioService.downloadProgress/100;
  }*/

  get downloadProgressMB(): number {
    return this.downloadProgress*this.downloadSize;
  }

/*  get downloadSize(): number {
    return this.audioService.downloadSize*1e-6;
  }*/

  ngOnInit() {
    this.audioDownloadService.progress$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(res => {
      this.ngZone.run(() => {
        this.downloadProgress = res/100;
        this.downloadSize = this.audioDownloadService.downloadSize*1e-6;
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.downloadProgress = 0;
    this.downloadSize = 0;
  }

  cancelDownload(): void {
//    this.audioService.unloadTune();
    this.modalController.dismiss(null, 'cancel');
    this.onCancel.emit();
    if(this.goToPlaylistID && this.routeBack) {
      this.audioDownloadService.cancelDownloads();
      this.router.navigateByUrl(`/collection/library/playlist-list/${ this.goToPlaylistID }`, { state: { direction: 'back' } });
    } else if(this.routeBack) {
      this.audioDownloadService.cancelDownloads();
      this.location.back();
    }
  }
}
