import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TempoSliderComponent } from '../../../components/tempo-slider/tempo-slider.component';
import { AudioService } from 'src/app/services/audio/audio.service';
import { LoadingPage } from '../loading/loading/loading.page';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-select-tempo',
    templateUrl: './select-tempo.page.html',
    styleUrls: ['./select-tempo.page.scss'],
    standalone: true,
    imports: [
        IonicModule,
        TempoSliderComponent,
        TranslateModule,
    ],
})
export class SelectTempoPage implements OnInit, OnDestroy {
  @Input() ticks: number[] = []; // Define ticks as an input property
  @Input() minTempo: number = 0;
  @Input() maxTempo: number = 120;
  @Input() startValue: number = 100;

  initTempo: number = 0;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private audioService: AudioService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.initTempo = this.audioService.currentTempo;
  }

  async ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  dismiss() {
    this.modalController.dismiss();
//    this.audioService.currentTempo = this.initTempo;
  }

  tempoChange(event: number) {
    this.audioService.currentTempo = event;
  }

  confirm() {

    this.modalController.dismiss();
  }

  changeSpeed() {
  }
}
