<ion-content scrollY="false" style="color: black" [color]="color">
  <qrcode
    [qrdata]="qrString"
    [cssClass]="'center'"
    [width]="250"
    [errorCorrectionLevel]="'M'"
    [colorLight]="'#00000000'"
  ></qrcode>
  <div style="color: black;" class="ion-text-center">{{ helpString }}</div>
  <div style="height: 0; color: #00000000;" class="ion-text-center" role="button" (click)="closeModal()">{{ 'USERMENU.CLOSE' | translate }}</div>
</ion-content>
