<div style="height: 200px;" class="custom-slider" #customSlider>
  <!--<ngx-slider [(value)]="value" [options]="options"
  [manualRefresh]="manualRefresh"
  (userChange)="tempoChange()"></ngx-slider>-->

  <input
    type="range"
    class="vertical-slider"
    [(ngModel)]="value"
    [min]="minTempo"
    [max]="maxTempo"
    (change)="onChange($event)"
  />
  @if (ticks) {
    <div class="vertical-slider-ticks" aria-hidden="true">
      @for (tick of ticks; track $index) {
        <div
          class="vertical-slider-tick"
          [style.bottom.%]="calculateTickPosition(tick)"
          [style.background-color]="getBackgroundColor(tick)"
        ></div>
        <div
          class="vertical-slider-value"
          [style.bottom.%]="calculateTickPosition(value)"
        >{{ value }}</div>
      }
    </div>

    @for (tick of ticks; track $index) {
      <div
        class="vertical-slider-tick-value"
        [style.bottom.px]="calculateTickValuePosition(tick)"
        (click)="setTempo(tick)"
        aria-hidden="true"
      >{{ tick }}</div>
    }

    <div class="vertical-slider-progress"
      [style.height.%]="calculateTickPosition(value)"
      [style.z-index]="1"
      aria-hidden="true"
    >
    </div>
    <div class="vertical-slider-progress"
      [style.height.%]="100"
      [style.background-color]="'white'"
      [style.z-index]="0"
      aria-hidden="true"
    >
    </div>
  }
</div>
<ion-input aria-label="tempo" type="number"
    [value]="value"
    [min]="minTempo" [max]="maxTempo"
    (ionChange)="setTempoEvent($event)"
    class="ion-text-center tempo-input"
    [helperText]="helperText" errorText="not in range"></ion-input>


