import { AuthGuardService } from './guards/auth-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'collection/library',
    pathMatch: 'full'
  },
  {
    path: 'collection',
    loadChildren: () => import('./pages/collection/collection.module').then( m => m.CollectionPageModule),
//    canActivate: [AuthGuardService]
  },
  {
    path: 'tune/:tuneId',
    loadChildren: () => import('./pages/player/player.module').then( m => m.PlayerPageModule),
//    canActivate: [AuthGuardService]
  },
  {
    path: 'tune/:playlistId/:tuneIndex',
    loadChildren: () => import('./pages/player/player.module').then( m => m.PlayerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: '**',
    redirectTo: 'collection/library'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
