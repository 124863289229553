import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";
import { Observable, catchError, map, of } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root'})
export class UsernameValidator implements AsyncValidator {
  httpClient = inject(HttpClient)

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const username = control.value;
    if(username.length > 2) {
      const headers = new HttpHeaders().set('Authorization', 'Bearer '+environment.apiKey);
      return this.httpClient.get<any>(`${environment.apiURL}/check-username/${username}`, {headers}).pipe(
        map((response) => {
          if (response.available) {
            return null; // Username is available
          } else {
            const suggestions = response.suggestions || []; // Extract suggestions if available
            return { usernameTaken: true, suggestedUsernames: suggestions };
          }
        }),
        catchError(() => of(null)) // Handle errors, return null for simplicity
      );
    }
    return of(null)
  }
}
