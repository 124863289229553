<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start"(click)="dismiss()">
      <ion-icon name="close" class="close-icon" color="primary" ariaLabel="{{ 'STORE.CLOSE' | translate }}"></ion-icon>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content scroll-y="true">
  <div class="cover">
    <app-cached-image [src]="getTuneIconSrc(tune)"></app-cached-image>
  </div>
  <div class="ion-text-center">
    <ion-button slot="end" fill="solid" (click)="purchase()" *ngIf="tuneSku && !tuneSku.isPurchased" class="button-container" [disabled]="!isOnline">
      {{ price | number: '1.0-2' | currency: currencyCode+' ' }}
    </ion-button>
    <ion-button slot="end" fill="outline"  *ngIf="tuneSku && tuneSku.isPurchased" disabled="true" class="button-container">
      {{ 'STORE.BOUGHT' | translate }}
    </ion-button>
  </div>
  <div class="cover-text ion-text-left">
    <div class="property-margin">
      <ion-text color="light" class="ion-margin-bottom" [ariaLabel]="ariaTonalitiesString" role="heading">
        {{ tonalitiesString }}
      </ion-text>
    </div>
    <div class="property-margin">
      <ion-text>
        {{ tempiString }}
      </ion-text>
    </div>
    @if(musiciansString) {
      <div class="property-margin">
        <ion-text>
          {{ 'STORE.MUSICIANS' | translate }}: {{ musiciansString }}
        </ion-text>
      </div>
    }
    <div class="tune-property" *ngIf="hasLevels" >
      <app-level-icon [hasLevels]="true" [level]="2"></app-level-icon>
      <ion-text>{{ levelsString }}</ion-text>
    </div>
    <ion-text [innerHTML]="coverText" color="light" [ariaLabel]="ariaCovertext">
    </ion-text>
  </div>
</ion-content>
