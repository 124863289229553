import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selected = '';
  locale = ''

  constructor(
    private translate: TranslateService,
  ) { }

  setInitialAppLanguage() {
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    this.selected = language;

    this.locale = window.navigator.language;
  }

  getLanguages() {
    return [
      { text: 'English', value: 'en' },
      { text: 'Deutsch', value: 'de' }
    ];
  }

  setLanguage(lng) {
    if (lng) {
      this.translate.use(lng);
      this.selected = lng;
    }
  }

  getLanguage(value: string) {
    return this.getLanguages().find(l => l.value===value);
  }

}
