import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { LibraryService } from '../library.service';
import { BandonPurchase } from '../../shared/interfaces/tune-sku';
import { ConnectivityService } from '../connectivity/connectivity.service';

const CACHE_KEY = '_bandoncached_';
const DOWNLOADSTATE_KEY = 'tuneDownloadState_';
const UNREGPURCHASES_KEY = 'unregisteredPurchases_';
const TOKEN_KEY = 'bandon_token'
const REFRESH_TOKEN_KEY = 'bandon_refreshtoken'
const TTL = 3600; //seconds

@Injectable({
  providedIn: 'root'
})
export class CachingService {

  constructor(
    private storage: Storage,
    private connServ: ConnectivityService
  ) {}

  async init() {
    await this.initStorage();
    await this.createImgCacheFolder();
    await this.createSheetsCacheFolder();
  }

  async initStorage() {
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();

  }

  cacheRequest(url, data) {
    const validUntil = (new Date().getTime()) + TTL * 1000;

    url = `${CACHE_KEY}${url}`;

    return this.storage.set(url, { validUntil, data });
  }

  async getCachedRequest(url) {
    const currentTime = new Date().getTime();
    url  = `${CACHE_KEY}${url}`;

    const storedValue = await this.storage.get(url);

    if (!storedValue) {
      return null;
    } else if (storedValue.validUntil < currentTime && this.connServ.isConnected()) {
//      await this.storage.remove(url);
      return null;
    } else {
      return storedValue.data;
    }
  }

  async clearCachedData() {
    const keys = await this.storage.keys();

    keys.map(async key => {
      if (key.startsWith(CACHE_KEY)) {
        await this.storage.remove(key);
      }
    });
  }

  async invalidateCachedData() {
    const keys = await this.storage.keys();

    keys.map(async key => {
      if (key.startsWith(CACHE_KEY)) {
        const entry = await this.storage.get(key);
        if(entry.validUntil) {
          entry.validUntil = (new Date().getTime()) - 2000;
          await this.storage.set(key, entry);
        }
      }
    });
  }

  async invalidateCacheEntry(url) {
    url = `${CACHE_KEY}${url}`;
    await this.storage.remove(url);
  }

  async createImgCacheFolder() {
    await Filesystem.readdir({
      directory: Directory.Library,
      path: `${environment.imgCacheFolder}`
    }).catch(() => {
      Filesystem.mkdir({
        directory: Directory.Library,
        path: `${environment.imgCacheFolder}`
      });
    });

    await Filesystem.readdir({
      directory: Directory.Cache,
      path: `${environment.avatarCacheFolder}`
    }).catch(() => {
      Filesystem.mkdir({
        directory: Directory.Cache,
        path: `${environment.avatarCacheFolder}`
      });
    });

    await Filesystem.readdir({
      directory: Directory.Cache,
      path: `${environment.groupImgCacheFolder}`
    }).catch(() => {
      Filesystem.mkdir({
        directory: Directory.Cache,
        path: `${environment.groupImgCacheFolder}`
      });
    });
  }

  async createSheetsCacheFolder() {
    await Filesystem.readdir({
      directory: Directory.Library,
      path: `${environment.sheetsCacheFolder}`
    }).catch(() => {
      Filesystem.mkdir({
        directory: Directory.Library,
        path: `${environment.sheetsCacheFolder}`
      });
    });
  }

  async clearCachedImages() {
    //TODO
    const fileEntries = await Filesystem.readdir({
      directory: Directory.Library,
      path: environment.imgCacheFolder
    });

    fileEntries.files.map(async f => {
      await Filesystem.deleteFile({
        directory: Directory.Library,
        path: `${environment.imgCacheFolder}/${f.name}`
      });
    });

    const groupImgEntries = await Filesystem.readdir({
      directory: Directory.Cache,
      path: environment.groupImgCacheFolder
    });

    groupImgEntries.files.map(async f => {
      await Filesystem.deleteFile({
        directory: Directory.Cache,
        path: `${environment.groupImgCacheFolder}/${f.name}`
      });
    });
  }

  async clearCachedImage(filename: string) {
    await Filesystem.deleteFile({
      directory: Directory.Library,
      path: `${environment.imgCacheFolder}/${filename}`
    }).then(result => console.log('removed Image: ', filename))
    .catch(error => console.log('error removing file: ', filename));
  }

  async clearCachedGroupImage(filename: string) {
    await Filesystem.deleteFile({
      directory: Directory.Cache,
      path: `${environment.groupImgCacheFolder}/${filename}`
    }).then(result => console.log('removed Group Image: ', filename))
    .catch(error => console.log('error removing file: ', filename));
  }

  cacheDownloadedState(tuneID, isDownloaded) {
    const validUntil = (new Date().getTime()) + TTL * 1000;

    const storageKey = `${CACHE_KEY}${DOWNLOADSTATE_KEY}${tuneID}`;

    return this.storage.set(storageKey, { validUntil, data: isDownloaded });
  }

  async getCachedDownloadState(tuneID) {
    const currentTime = new Date().getTime();
    const storageKey = `${CACHE_KEY}${DOWNLOADSTATE_KEY}${tuneID}`;

    const storedValue = await this.storage.get(storageKey);

    if (!storedValue) {
      return null;
    } else if (storedValue.validUntil < currentTime) {
      await this.storage.remove(storageKey);
//      console.log('get Cached download State: invalidate', tuneID);
      return null;
    } else {
//      console.log('get Cached download State: ', tuneID, storedValue.data);
      return storedValue.data;
    }
  }

  cacheUnregisteredPurchases(purchases: BandonPurchase[]) {
    const storageKey = `${CACHE_KEY}${UNREGPURCHASES_KEY}`;

    return this.storage.set(storageKey, purchases );
  }

  getUnregisteredPurchases(): Promise<any> {
    const storageKey = `${CACHE_KEY}${UNREGPURCHASES_KEY}`;

    return this.storage.get(storageKey);
  }

  cacheTokens(token: any, refreshToken: any) {
    this.storage.set(TOKEN_KEY, token);
    this.storage.set(REFRESH_TOKEN_KEY, refreshToken);
  }

  async getToken() {
    return await this.storage.get(TOKEN_KEY);
  }

  async getRefreshToken() {
    return await this.storage.get(REFRESH_TOKEN_KEY);
  }

  clearTokens() {
    this.storage.remove(TOKEN_KEY);
    this.storage.remove(REFRESH_TOKEN_KEY);
  }
}
