<div class="slider-container" aria-labelledby="timeinfo">
  <ion-range [min]="loopStart" [max]="loopEnd" step="3.0" debounce="0"
      [(ngModel)]="currentTime"
      (ionInput)="setCurrentTime($event)" (ionKnobMoveStart)="startJump($event)"
      (ionKnobMoveEnd)="jumpTo($event)"
      ariaLabel="{{ 'PLAYER.TIME' | translate }}">
  </ion-range>
  <span class="visually-hidden ion-text-center" #timeinfo>{{ rangeAriaLabel }}</span>
  <div class="loop-start" *ngIf="showLoop" aria-hidden="true">
    {{ loopStartString }}
  </div>
  <div class="loop-bar-start" *ngIf="showLoop && hasBarInformation" aria-hidden="true">
    {{ loopStartBarString }}
  </div>
  <div class="loop-end" *ngIf="showLoop" aria-hidden="true">
    {{ loopEndString }}
  </div>
  <div class="loop-bar-end" *ngIf="showLoop && hasBarInformation" aria-hidden="true">
    {{ loopEndBarString }}
  </div>
  <div class="time-string ion-text-center" aria-hidden="true">
    {{ currentTimeString }}
  </div>
  <div class="ion-text-center bar-string" *ngIf="hasBarInformation" aria-hidden="true">
    {{ currentBarString }}
  </div>
</div>
