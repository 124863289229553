<div *ngIf="showNewPlaylistIcon" class="tune-img newPlaylist">
  <ion-icon name="add" color="primary" *ngIf="playlist.id===-1" class="add-icon"></ion-icon>
</div>
<div *ngIf="!showNewPlaylistIcon && playlistImgSrc.length<4 && !customPicture" class="tune-img">
  <app-cached-image [src]="playlistImgSrc[0]"></app-cached-image>
</div>
<div *ngIf="!showNewPlaylistIcon && playlistImgSrc.length>=4 && !customPicture" class="tune-img">
  <app-cached-image [src]="playlistImgSrc[0]" class="grid-image"></app-cached-image>
  <app-cached-image [src]="playlistImgSrc[1]" class="grid-image"></app-cached-image>
  <app-cached-image [src]="playlistImgSrc[2]" class="grid-image"></app-cached-image>
  <app-cached-image [src]="playlistImgSrc[3]" class="grid-image"></app-cached-image>
</div>

